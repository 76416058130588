import React, { useEffect, useState} from 'react';
import s from './Entity.module.scss';
import { 
    ApplicationStepStatus,
    ApplicationSteps, 
    CompanyVerificationData, 
    AdditionalInformationData,
    EntityTradingAccountData,
    EntityFinancialInformationData,
    BankingInformationData,
    EntityDocumentsData,
    WalletAddressData,
    UBOVerificationData,
    DirectorData,
    AdditionalAuthorizedUserData,
    ReferralInformationData
} from '../../components/Types';
import { 
    saveCompanyVerificationInformation,
    saveCompanyAdditionalInformation,
    saveTradingInformation,
    saveFinancialInformation,
    saveBankingInformation,
    saveAuthUserInformation,
    saveUBOInformation,
    saveDirectorInformation,
    saveLegalDocAdditionalInformation,
    submitEntityForm
} from '../../api/entity';
import { saveWalletInformation, saveReferralInformation, saveDeclarationInformation } from '../../api/individual';
import { entityDocumentType, entityDocumentTypes, flowOfFundsFromFiatToCrypto, moneyServiceBusiness} from '../../constants/entityDocumentTypes';
import cn from 'classnames';
import { CompanyVerification, AdditionalInformation, TradingAccount, FinancialInformation, BankingInformation, UploadDocuments, WalletAddress, Declaration, UBOVerification, DirectorsAndAdditionalAuthorizedUsers, Referral } from './forms';
import { Button, ThemeSwitchToggle, CornerToaster, Modal } from '../../components/UILib';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTheme } from '../../contexts/ThemeContext';
import { getEntityAuthUserInfo } from '../../api/applicantion';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { fieldValidatingHandler, multiFormsValidatingHandler, multiFormsValidatingHandlerForBanks, multiUploadsValidatingHandler } from '../../utils/validations';
import { 
    companyVerificationFormRequired,
    entityAdditionalInformationFormRequired,
    entityAdditionalInformationOtherSourceFunding,
    entityAdditionalInformationOtherNatureBusiess,
    entityAdditionalInformationOperatingAddress,
    entityAdditionalInformationHasWebsite,
    entityAdditionalInformationNoWebsite,
    entityTradingInformationFormRequired,
    entityTradingInformationOtherPurposeRequired,
    walletAddressFormRequired,
    entityFinancialFormRequired,
    bankingFormRequired,
    bankingWithIntBank,
    entityDirectorFormRequired,
    entityUBOFormRequired,
    entityAuthorizedUserFormRequired,
    referralFormRequired
} from '../../constants/requiredFields';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils/responsiveBreakpoints';
const initialStatus : ApplicationStepStatus = {
    'Company Verification': false, //0
    'Additional Information': false, //1
    'Trading Account': false, //2
    'Wallet Address': false, //3
    'Financial Information': false, //4
    'Banking Information': false, //5
    'Upload Documents': false, //6
    'UBO Verification': false, //7
    'List of Directors and Additional Authorized Users': false,//8
    'Declaration': false, //9
    'Referral': false //10
}
let companyVerificationDefault : CompanyVerificationData = {
    applicant_legal_type: 'Corporation',
    other_legal_type: '',
    legal_identifier: '',
    country_of_incorporation: '',
    date_of_incorporation: '',
    regulated_by_gov: false,
    regulation_agency: '',
    bankruptcy: false,
    bankruptcy_details: '',
    pep: false,
    pep_details: '',
    financial_entity_in_us: false,
    swap_dealer: false,
}
let additionalInfoDefaults : AdditionalInformationData = {
    company_name: '',
    company_registered_number: '',
    contact_number: '',
    funding_source: '',
    other_funding_source: '',
    nature_of_business: '',
    other_nature_of_business: '',
    registered_address: '',
    registered_country: '',
    registered_city: '',
    registered_state: '',
    registered_postal_code: '',
    different_operating_address: false,
    operating_address: '',
    operating_country: '',
    operating_city: '',
    operating_state: '',
    operating_postal_code: '',
    has_website: true,
    website: '',
    service_description: '',
    service_marketing_description: '',
    inventory_storage_description: '',
    customer_base: '',
    terms_and_conditions_of_sale: '',
    supplier_agreement_upload_url: '',
    customer_service_email: '',
    linkedin: '',
    instagram: '',
    twitter: '',
}
const tradingInfoDefauls : EntityTradingAccountData = {
    purpose: [],
    other_purpose: '',
    date_of_first_trade: '',
    currency_of_first_trade: '',
    size_of_first_trade: '',
    currency_to_be_traded: '',
    monthly_volume_to_be_traded: '',
    flow_of_first_trade: '',
}
const financialInfoDefaults : EntityFinancialInformationData = {
    income_currency: '',
    income: '',
    liquid_asset_currency: '',
    liquid_asset_value: '',
    is_accredited_investor: false,
}
const bankingInfoDefaults : BankingInformationData[] = [{
    bank_account_name: '',
    account_number: '',
    routing_code:'',
    swift_code: '',
    beneficiary_address: '',
    bank_name: '',
    bank_address: '',
    country: '',
    account_currency: '',
    has_intermediary_bank: false,
    intermediary_bank_name: '',
    intermediary_routing_number: '',
    intermediary_bank_address: '',
}]
const uploadDocInfo: EntityDocumentsData[] = [{
    type: '',
    doc_link: ''
}]
const uboVerificationInfoDefaults : UBOVerificationData[] = [{
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    country: '',
    ownership: '',
}]
const directorInfoDefaults : DirectorData[] = [{
    title: '',
    full_name: '',
    email: '',
    contact_number: '',
    nationality: '',
}]
const authorizedUsersInfoDefaults : AdditionalAuthorizedUserData[] = [{
    full_name: '',
    email: '',
    contact_number: '',
}]
const walletAddressDefaults : WalletAddressData[] = [{
    type: '',
    wallet_address: ''
}]
const referralInfoDefaults : ReferralInformationData = {
    source: '',
    referred_by: '',
}
function Entity() {
    const navigate = useNavigate();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [user, setUser] = useState<any>();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const { theme, toggleTheme } = useTheme();
    const [curStep, setCurStep] = useState<number>(0);
    // submission related states
    const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
    const [submissionOpen, setSubmissionOpen] = useState<boolean>(false);
    const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
    // page info save 
    const [informationSaveOpen, setInformationSaveOpen] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
    const [savedPage, setSavedPage] = useState<string>('');
    // validation alerts 
    const [alertId, setAlertId] = useState<string>('');
    const [openValidation, setOpenValidation] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<React.ReactNode>('');
    const [alertSuccessId, setAlertSuccessId] = useState<string>('');
    const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
    // each page completion status
    const [formCompletionStatus, setFormCompletionStatus] = useState<ApplicationStepStatus>(initialStatus)
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    // page info
    const [companyVerification, setCompanyVerification] = useState<CompanyVerificationData>(companyVerificationDefault);
    const [additionalInformation, setAdditionalInformation] = useState<AdditionalInformationData>(additionalInfoDefaults);
    const [tradingInformation, setTradingInformation] = useState<EntityTradingAccountData>(tradingInfoDefauls);
    const [financialInformation, setFinancialInformation] = useState<EntityFinancialInformationData>(financialInfoDefaults);
    const [bankingInformation, setBankingInformation] = useState<BankingInformationData[]>(bankingInfoDefaults);
    // related to upload documents 
    const [initialTradeFiatToCrypto, setInitialTradeFiatToCrypto] = useState<boolean>(false);
    const [isMSB, setIsMSB] = useState<boolean>(false);
    const [uploadDocuments, setUploadDocuments] = useState<EntityDocumentsData[]>([]);
    const [uboVerification, setUboVerification] = useState<UBOVerificationData[]>(uboVerificationInfoDefaults);
    const [directors, setDirectors] = useState<DirectorData[]>(directorInfoDefaults);
    const [authorizedUsers, setAuthorizedUsers] = useState<AdditionalAuthorizedUserData[]>(authorizedUsersInfoDefaults);
    const [walletAddress, setWalletAddress] = useState<WalletAddressData[]>(walletAddressDefaults);
    const [declaration, setDeclaration] = useState<boolean>(false);
    const [referralInformation, setReferralInformation] = useState<ReferralInformationData>(referralInfoDefaults);
    // field error states
    const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
    const [invalidFormInputs, setInvalidFormInputs] = useState<any[]>([]);
    const [invalidDirectorInputs, setInvalidDirectorInputs] = useState<any[]>([]);
    const [invalidAuthUserInputs, setInvalidAuthUserInputs] = useState<any[]>([]);
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const entitySteps: ApplicationSteps = {
        'Company Verification': 'ApplicationInformation', //0
        'Additional Information': 'ResidentialInformation', //1
        'Trading Account': 'TradingAccount', //2
        'Wallet Address': 'WalletAddress', //3
        'Financial Information': 'FinancialInformation', //4
        'Banking Information': 'BankingInformation', //5
        'Upload Documents': 'UploadDocuments', //6
        'UBO Verification': 'UBOVerification', //7
        'List of Directors and Additional Authorized Users': 'DirectorsAndAdditionalAuthorizedUsers',//8
        'Declaration': 'Declaration', //9
        'Referral': 'Referral' //10
    }
    useEffect(() => {
        if(userId) {
            getUserInfo(Number(userId))
        }
        else {
            //TODO: ERROR ALERT -> CANT RETRIEVE USER INFO
        }
    },[userId])
    const getUserInfo = async(userId: number) => {
        let resUser = await getEntityAuthUserInfo(userId)
       
        setUser(resUser)
        return resUser
    }
    // map user info to states
    useEffect(() => {
     
        let list = [...Object.keys(entityDocumentTypes[companyVerification.applicant_legal_type]), ...Object.keys(flowOfFundsFromFiatToCrypto), ...Object.keys(moneyServiceBusiness)]
        if(user){
            // map it to application 
            let verificationInfo = user["entity_company_verification_information"]
            if(verificationInfo){
                list = [...Object.keys(entityDocumentTypes[verificationInfo.applicant_legal_type]), ...Object.keys(flowOfFundsFromFiatToCrypto), ...Object.keys(moneyServiceBusiness)]
                setCompanyVerification({
                    applicant_legal_type: verificationInfo.applicant_legal_type || 'Corporation',
                    other_legal_type: verificationInfo.other_legal_type || '',
                    legal_identifier: verificationInfo.legal_identifier || '',
                    //TODO: INVESTIGATE THE TYPO IN BACKEND
                    country_of_incorporation: verificationInfo.country_of_incorporatio || '',
                    date_of_incorporation: verificationInfo.date_of_incorporation || '',
                    regulated_by_gov: verificationInfo.regulated_by_gov || false,
                    regulation_agency:  verificationInfo.regulation_agency || '',
                    bankruptcy: verificationInfo.bankruptcy || false,
                    bankruptcy_details: verificationInfo.bankruptcy_details || '',
                    pep: verificationInfo.pep || false,
                    pep_details: verificationInfo.pep_details || '',
                    financial_entity_in_us: verificationInfo.financial_entity_in_us || false,
                    swap_dealer: verificationInfo.swap_dealer || false,
                });
            }
            
            let additionalInfo = user["entity_additional_information"]
            if(additionalInfo) {
                setAdditionalInformation({
                    company_name: additionalInfo.company_name || '',
                    company_registered_number: additionalInfo.company_registered_number || '',
                    contact_number: additionalInfo.contact_number ||'',
                    funding_source: additionalInfo.funding_source ||'',
                    other_funding_source: additionalInfo.other_funding_source ||'',
                    nature_of_business: additionalInfo.nature_of_business ||'',
                    other_nature_of_business: additionalInfo.other_nature_of_business ||'',
                    registered_address: additionalInfo.registered_address ||'',
                    registered_country: additionalInfo.registered_country ||'',
                    registered_city: additionalInfo.registered_city ||'',
                    registered_state: additionalInfo.registered_state ||'',
                    registered_postal_code: additionalInfo.registered_postal_code ||'',
                    different_operating_address: additionalInfo.different_operating_address,
                    operating_address: additionalInfo.operating_address ||'',
                    operating_country: additionalInfo.operating_country ||'',
                    operating_city: additionalInfo.operating_city ||'',
                    operating_state: additionalInfo.operating_state ||'',
                    operating_postal_code: additionalInfo.operating_postal_code ||'',
                    has_website: additionalInfo.has_website,
                    website: additionalInfo.website ||'',
                    service_description: additionalInfo.service_description ||'',
                    service_marketing_description: additionalInfo.service_marketing_description ||'',
                    inventory_storage_description: additionalInfo.inventory_storage_description ||'',
                    customer_base: additionalInfo.customer_base ||'',
                    terms_and_conditions_of_sale: additionalInfo.terms_and_conditions_of_sale ||'',
                    supplier_agreement_upload_url: additionalInfo.supplier_agreement_upload_url ||'',
                    customer_service_email: additionalInfo.customer_service_email ||'',
                    linkedin: additionalInfo.linkedin ||'',
                    instagram: additionalInfo.instagram ||'',
                    twitter: additionalInfo.twitter ||'',
                })
            }
            
            let tradinglInfo = user["entity_trading_account"]
            if(tradinglInfo) {
                setTradingInformation({
                    purpose: tradinglInfo.purpose || [],
                    other_purpose: tradinglInfo.other_purpose ||'',
                    date_of_first_trade: tradinglInfo.date_of_first_trade ||'',
                    currency_of_first_trade: tradinglInfo.currency_of_first_trade ||'',
                    size_of_first_trade: tradinglInfo.size_of_first_trade ||'',
                    currency_to_be_traded: tradinglInfo.currency_to_be_traded ||'',
                    monthly_volume_to_be_traded: tradinglInfo.monthly_volume_to_be_traded ||'',
                    flow_of_first_trade: tradinglInfo.flow_of_first_trade || '',
                })
            }
            let financialInfo = user["entity_financial_information"]
            if(financialInfo) {
                setFinancialInformation({
                    income_currency: financialInfo.income_currency || '',
                    income: financialInfo.income ||'',
                    liquid_asset_currency: financialInfo.liquid_asset_currency ||'',
                    liquid_asset_value: financialInfo.liquid_asset_value ||'',
                    is_accredited_investor: financialInfo.is_accredited_investor || false
                })
            }
            let bankingInfo = user["entity_banking_information"]
            if(bankingInfo.length > 0) {
                setBankingInformation(bankingInfo)
            }
            let tradeFlow = user['entity_additional_legal_information']
            if(tradeFlow) {
                if(tradinglInfo.flow_of_first_trade == "Fiat to Crypto"){
                    setInitialTradeFiatToCrypto(true)
                }
                else {
                    setInitialTradeFiatToCrypto(false)
                }
                setIsMSB(tradeFlow.is_msb)
            }
            let docInfo = user["entity_documents"]
            if(docInfo.length > 0) {
                // TODO: FETCH THE EXACT LIST FROM BACKEND
                // let list = [...Object.keys(entityDocumentTypes[companyVerification.applicant_legal_type]), ...Object.keys(flowOfFundsFromFiatToCrypto), ...Object.keys(moneyServiceBusiness)]
             
                let entityDocumentList:EntityDocumentsData[] = list.map(doc => ({type: doc, doc_link: ''}))
                const updated = mergeDocuments(entityDocumentList, docInfo);
              
                
                setUploadDocuments(updated);
                //const updated = mergeDocuments(docInfo, uploadDocuments);
                // setUploadDocuments(docInfo);
            }
            let uboInfo = user['entity_ubo_verification_information']
            if(uboInfo.length > 0) {
                setUboVerification(uboInfo)
            }
            let directorInfo = user['entity_director']
            if(directorInfo.length > 0) {
                setDirectors(directorInfo)
            }
            let authorizedUsers = user['entity_additional_authorized_users_information']
            if(authorizedUsers.length> 0){
                setAuthorizedUsers(authorizedUsers)
            }
            let walletInfo = user["wallet_addresses"]
            if(walletInfo.length > 0) {
                setWalletAddress(walletInfo)
            }
            let declarationInfo = user["declaration"]
            if(declarationInfo){
                setDeclaration(declarationInfo.signed)
            }
            let referralInfo = user["referral_informations"]
            if(referralInfo) {
                setReferralInformation({
                    source: referralInfo.source || '',
                    referred_by: referralInfo.referred_by || '',
                })
            }
        }
    },[user])
    const mergeDocuments = (currentDocs: EntityDocumentsData[], newDocs: EntityDocumentsData[]) => {
        // Map over current documents to update or retain existing entries
        
        const updatedDocs = currentDocs.map(doc => {
            //console.log('currentDocs', doc)
            // Find a new document that matches the type of the current document
            const matchingNewDoc = newDocs.find(newDoc => newDoc.type == doc.type);
            //console.log('matchingNewDoc', matchingNewDoc);
            
            // If a match is found, update the doc_link, otherwise return the original
            return matchingNewDoc ? { ...doc, doc_link: matchingNewDoc.doc_link } : doc;
        });
       
        
        // Filter out newDocs that are already included in the updatedDocs
        // const newDocsToAdd = newDocs.filter(newDoc => 
        //     !currentDocs.some(doc => doc.type === newDoc.type));
       
        // Combine the updated existing docs with any new docs that weren't already present
        return [...updatedDocs];
    };
    useEffect(() => {
        // TODO: FETCH THE EXACT LIST FROM BACKEND
       
        let originaDocs = Object.keys(entityDocumentTypes[companyVerification.applicant_legal_type])
        //const originaDocLength = Object.keys(entityDocumentTypes[companyVerification.applicant_legal_type]).length
        originaDocs.splice(-1, 0, ...Object.keys(flowOfFundsFromFiatToCrypto));
        
        
        let list = [...originaDocs, ...Object.keys(moneyServiceBusiness)]
       
        
        let entityDocumentList:EntityDocumentsData[] = list.map(doc => ({type: doc, doc_link: ''}))
        const updated = mergeDocuments(entityDocumentList, uploadDocuments);
       
        
        setUploadDocuments(updated);
        //setUploadDocuments(entityDocumentList)
    },[companyVerification.applicant_legal_type])

    const stepsArray = Object.keys(entitySteps)

    const handleStepperClick = async (step: keyof typeof entitySteps, index: number) => {
        handlePagesInfoSave(curStep, false)
       
        setOpenValidation(false)
        setValidationSuccess(false)
        setCurStep(index)
        // setCurStep(index)
    }
    const onNextPage = async() => {
        setOpenValidation(false)
        setValidationSuccess(false)
        if (curStep < stepsArray.length - 1) {
            setCurStep(curStep + 1);
            await handlePagesInfoSave(curStep, false)
        } else {
          
        }
    }
    const onPreviousPage = async() => {
        setOpenValidation(false)
        setValidationSuccess(false)
        if (curStep > 0) {
            setCurStep(curStep - 1);
            await handlePagesInfoSave(curStep, false)
        } else {
          
        }
    };
    const handleLogOut = () => {
        navigate('/');
    }
    const quitButton = (
        <div className={s.buttonContainer}>
            <Button variant="outline" size="sm" className={s.button} onClick={handleLogOut}>Exit Application</Button>
        </div>  
    )
    const handlePagesInfoSave = async(curAppStep: number, notify: boolean) => {
        setPageLoading(true)
        
        let res : any;
        if(userId) {
            if(curAppStep === 0) { // Company Verification
                res = await saveCompanyVerificationInformation(Number(userId), companyVerification)
               
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 1) { //Additional Information
                res = await saveCompanyAdditionalInformation(Number(userId), additionalInformation)
              
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 2) {
                res = await saveTradingInformation(Number(userId), tradingInformation)
           
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 3) {
                res = await saveWalletInformation(Number(userId), walletAddress)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 4) {
                res = await saveFinancialInformation(Number(userId), financialInformation)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 5) {
                res = await saveBankingInformation(Number(userId), bankingInformation)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 6) {
                res = await saveLegalDocAdditionalInformation(Number(userId), initialTradeFiatToCrypto, isMSB)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 7) {
                res = await saveUBOInformation(Number(userId), uboVerification)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 8) {
                res = await saveDirectorInformation(Number(userId), directors)
                res = await saveAuthUserInformation(Number(userId), authorizedUsers)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 9) {
                res = await saveDeclarationInformation(Number(userId), declaration)
                setSavedPage(stepsArray[curAppStep])
            }
            else if(curAppStep === 10) {
                res = await saveReferralInformation(Number(userId), referralInformation)
                setSavedPage(stepsArray[curAppStep])
            }
          
            setPageLoading(false)
            if(res && res.status === 201 && notify){
                setInformationSaveOpen(true)
                setSaveSuccess(true)
            }
            // else {
            //     setInformationSaveOpen(true)
            //     setSaveSuccess(true)
            // }
        }
        else {
            setPageLoading(false)
            // TODO: userid not present in url, throw error
          
            if(notify) {
                setInformationSaveOpen(true)
                setSaveSuccess(false)
            }
        }
    }
    useEffect(() => {
        // update completion status
        let verificationInfo = fieldValidatingHandler(companyVerificationFormRequired, companyVerification)
     
        // addtional info
        let requiredFields = entityAdditionalInformationFormRequired
        if(additionalInformation.funding_source == 'Other') {
            requiredFields = {...requiredFields, ...entityAdditionalInformationOtherSourceFunding}
        }
        if(additionalInformation.nature_of_business == 'Other') {
            requiredFields = {...requiredFields, ...entityAdditionalInformationOtherNatureBusiess}
        }
        if(additionalInformation.different_operating_address) {
            requiredFields = {...requiredFields, ...entityAdditionalInformationOperatingAddress}
        }
        if(additionalInformation.has_website) {
            requiredFields = {...requiredFields, ...entityAdditionalInformationHasWebsite}
        }
        else {
            requiredFields = {...requiredFields, ...entityAdditionalInformationNoWebsite}
        }
        let addInfo = fieldValidatingHandler(requiredFields, additionalInformation)
        let tradeInfo = tradingInformation.purpose.includes('Other')? 
            fieldValidatingHandler(entityTradingInformationOtherPurposeRequired, tradingInformation)
            :fieldValidatingHandler(entityTradingInformationFormRequired, tradingInformation)
        let finInfo = fieldValidatingHandler(entityFinancialFormRequired, financialInformation)
        let bankInfo = multiFormsValidatingHandlerForBanks(bankingFormRequired, bankingWithIntBank, bankingInformation)
        let bank =  bankInfo.every(arr => arr.length === 0) ? true: false
        let walletInfo = multiFormsValidatingHandler(walletAddressFormRequired, walletAddress)
        let wallet = walletInfo.every(arr => arr.length === 0) ? true: false
        let list ={...entityDocumentTypes[companyVerification.applicant_legal_type]}
        if(initialTradeFiatToCrypto && !isMSB) {
            list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...flowOfFundsFromFiatToCrypto}
        }
        else if(isMSB && !initialTradeFiatToCrypto) {
            list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...moneyServiceBusiness}
        }
        else if(isMSB && initialTradeFiatToCrypto){
            list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...flowOfFundsFromFiatToCrypto, ...moneyServiceBusiness}
        }
      
        
      
        
        let docInfo = multiUploadsValidatingHandler(list, uploadDocuments)

        let uboInfo = multiFormsValidatingHandler(entityUBOFormRequired, uboVerification)
        let ubo = uboInfo.every(arr => arr.length === 0) ? true: false
        let directorInfo = multiFormsValidatingHandler(entityDirectorFormRequired, directors)
        let director = directorInfo.every(arr => arr.length === 0) ? true: false
        let autUsersInfo = multiFormsValidatingHandler(entityAuthorizedUserFormRequired, authorizedUsers)
        let authUser = autUsersInfo.every(arr => arr.length === 0) ? true: false
        // //let upload = uploadDocuements.doc_link !== ''
        let referralInfo = fieldValidatingHandler(referralFormRequired, referralInformation)
        let updateCom = {
            ...formCompletionStatus, 
            'Company Verification': verificationInfo.length === 0,
            'Additional Information': addInfo.length === 0,
            'Trading Account': tradeInfo.length === 0,
            'Financial Information': finInfo.length === 0,
            'Banking Information': bank,
            'Upload Documents': docInfo.length === 0,
            'Wallet Address': wallet,
            'UBO Verification': ubo,
            'List of Directors and Additional Authorized Users': director&&authUser,//8
            'Declaration': declaration,
            'Referral': referralInfo.length === 0,
            // 'KYC': true
        }
        setFormCompletionStatus(updateCom)
        let isReady = Object.entries(updateCom).every(([key, value]) => {
            if (key === 'Wallet Address') {
                return true; // Always return true for 'Wallet Address', effectively ignoring its actual value
            }
            return value === true;
        });
        
        setReadyToSubmit(isReady)
       
        
    },[companyVerification, uploadDocuments, additionalInformation, tradingInformation, walletAddress, financialInformation, bankingInformation, uboVerification, directors, authorizedUsers, referralInformation, declaration, initialTradeFiatToCrypto, isMSB])
    const handleVerification = (curAppStep: number) => {
      
        if(userId) {
            if(curAppStep === 0) {
                //const requiredFields = individualApplicantFormRequired
                handleValidatingForm(companyVerificationFormRequired, companyVerification, 'Company Verification')
            }
            else if(curAppStep === 1) {
                // check other funding source
                let requiredFields = entityAdditionalInformationFormRequired
                if(additionalInformation.funding_source == 'Other') {
                    requiredFields = {...requiredFields, ...entityAdditionalInformationOtherSourceFunding}
                }
                if(additionalInformation.nature_of_business == 'Other') {
                    requiredFields = {...requiredFields, ...entityAdditionalInformationOtherNatureBusiess}
                }
                if(additionalInformation.different_operating_address) {
                    requiredFields = {...requiredFields, ...entityAdditionalInformationOperatingAddress}
                }
                if(additionalInformation.has_website) {
                    requiredFields = {...requiredFields, ...entityAdditionalInformationHasWebsite}
                }
                else {
                    requiredFields = {...requiredFields, ...entityAdditionalInformationNoWebsite}
                }
             
                
                //const requiredFields = individualResidentialFormRequired
                handleValidatingForm(requiredFields, additionalInformation, 'Additional Information')
            }
            else if(curAppStep === 2) { // trading account
                const requiredFields = tradingInformation.purpose.includes('Other') ? entityTradingInformationOtherPurposeRequired: entityTradingInformationFormRequired
                handleValidatingForm(requiredFields, tradingInformation, 'tradingInformation')
            }
            else if(curAppStep === 3) {
                //TODO: NO NEED TO VALIDATE WALLET ADDRESS
                handleValidatingMultiForms(walletAddressFormRequired, walletAddress, 'walletAddressInformation', 'Wallet')
            }
            else if(curAppStep === 4) {
                //const requiredFields = individualFinancialFormRequired
                handleValidatingForm(entityFinancialFormRequired, financialInformation, 'financialInformation')
            }
            else if(curAppStep === 5) {
                //const requiredFields = bankingFormRequired
                handleValidatingMultiForms(bankingFormRequired ,bankingInformation, 'bankingInformation', 'Banking', true)
            }
            else if(curAppStep === 6){
                //TODO: VALIDATE UPLOADED FILE
                if(!initialTradeFiatToCrypto && !isMSB){
                    handleValidatingRequiredUploads('uploadDocs', entityDocumentTypes[companyVerification.applicant_legal_type])
                }
                else if(initialTradeFiatToCrypto && !isMSB) {
                    let list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...flowOfFundsFromFiatToCrypto}
                    handleValidatingRequiredUploads('uploadDocs', list)
                }
                else if(isMSB && !initialTradeFiatToCrypto) {
                    let list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...moneyServiceBusiness}
                    handleValidatingRequiredUploads('uploadDocs', list)
                }
                else {
                    let list = {...entityDocumentTypes[companyVerification.applicant_legal_type], ...flowOfFundsFromFiatToCrypto, ...moneyServiceBusiness}
                    handleValidatingRequiredUploads('uploadDocs', list)
                }
            }
            else if(curAppStep === 7){ //UBO
                handleValidatingMultiForms(entityUBOFormRequired, uboVerification, 'uboInformation', 'UBO')
            }
            else if(curAppStep === 8){ //director and auth users
                // TODO: COMBINE 2 SECTION TOGETHER? OR SEPARAE?
                handleValidatingAuthUserPage(entityDirectorFormRequired, directors, entityAuthorizedUserFormRequired, authorizedUsers)
            }
            
            else if(curAppStep === 10) {
                //const requiredFields = referralFormRequired
                handleValidatingForm(referralFormRequired, referralInformation, 'referralInformation')
            }
        }
        else {
            // TODO: userid not present in url, throw error
        
            
        }
    }
    const handleValidatingForm = (requiredFields:Record<string, any>, form:Record<string, any>, id:string) => {
        // setOpenValidation(false)
        // setValidationSuccess(false)
      
        let fields = fieldValidatingHandler(requiredFields, form)
       
        setInvalidInputs(fields)
        let message =  fields.length> 0 ? 
                                    (<div>
                                        <ul>{fields.map(field => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                                        <p>Click verify to refresh the missing information</p>
                                    </div>) 
                                    : null
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleValidatingAuthUserPage = (directorRequired:Record<string, any>, directorForm: DirectorData[], authUserRequired:Record<string, any>, autUserForms: AdditionalAuthorizedUserData[]) => {
        let directors = multiFormsValidatingHandler(directorRequired, directorForm)
       
        setInvalidDirectorInputs(directors)
        let authUsers = multiFormsValidatingHandler(authUserRequired, autUserForms)
        setInvalidAuthUserInputs(authUsers)
      
        let message = [...directors, ...authUsers].filter(subArray => subArray.length > 0).length > 0 ? 
        (<>
            {directors.filter(subArray => subArray.length > 0).length > 0 ? 
            <div>
                <p style={{textAlign: 'left', fontWeight: 'bold'}}>Directors</p>
                <ul>{directors.map((d:Record<string, any>, index: number) => d.length > 0 ? (
                    <>
                        <p style={{textAlign: 'left'}}>Director #{index + 1}</p>
                        <ul style={{textAlign: 'left'}}>{d.map((field:string) => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                    </>
                ): null)}</ul>
            </div>
            : null}
            {authUsers.filter(subArray => subArray.length > 0).length > 0 ? 
            <div>
                <p style={{textAlign: 'left', fontWeight: 'bold'}}>Authorized Users</p>
                <ul>{authUsers.map((a:Record<string, any>, index: number) => a.length > 0 ? (
                    <>
                        <p style={{textAlign: 'left'}}>Authorized User #{index + 1}</p>
                        <ul style={{textAlign: 'left'}}>{a.map((field:string) => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                    </>
                ): null)}</ul>
            </div>
            : null}
            <p>Click verify to refresh the missing information</p>
        </>) : null
        if(message){
            // setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId('SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleValidatingMultiForms = (requiredFields:Record<string, any>, form:Record<string, any>[], id:string, formName: string, isBanking:boolean = false) => {
        let forms = isBanking? multiFormsValidatingHandlerForBanks(bankingFormRequired, bankingWithIntBank, bankingInformation):multiFormsValidatingHandler(requiredFields, form)
        //let forms = multiFormsValidatingHandler(requiredFields, form)
        setInvalidFormInputs(forms)
     
        
        let message =  forms.every(arr => arr.length === 0) ? 
                                    null
                                    : (<>{forms.map((el, index) => el.length > 0 ? (
                                            <div>
                                                <p style={{textAlign: 'left', fontWeight: 'bold'}}>{formName} #{index + 1}</p>
                                                <ul>{el.map((field:string) => <li style={{textAlign: 'left'}}>{field}</li>)}</ul>
                                            </div>
                                            ): null)}
                                            <p>Click verify to refresh the missing information</p>
                                        </>)
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleValidatingRequiredUploads = (id: string, entityDocTypes: Record<string, boolean>) => {
        // TODO: NEED TO UPDATE IT TO VALIDATE MORE FILES
        setOpenValidation(false)
        let list = [...Object.keys(entityDocumentTypes[companyVerification.applicant_legal_type]), ...Object.keys(flowOfFundsFromFiatToCrypto), ...Object.keys(moneyServiceBusiness)]


        let missingDocs = multiUploadsValidatingHandler(entityDocTypes, uploadDocuments)
     
        let message =  missingDocs.length > 0 ? (<div>
                            <ul>{missingDocs.map((field, index) => <li key={index} style={{textAlign: 'left'}}>{field}</li>)}</ul>
                            <p>Click verify to refresh the missing information</p>
                        </div>) : null
        //let message = user.entity_documents.length > 0 ? null : 'Proof of Funds'
        if(message){
            setAlertId(id)
            setOpenValidation(true)
            setValidationSuccess(false)
            setValidationMessage(message)
            
        }
        else {
            setAlertSuccessId(id+'SUCCESS')
            setOpenValidation(true)
            setValidationSuccess(true)
            
        }
    }
    const handleSubmitApplication = async() => {
        setSubmissionLoading(true)
        await handlePagesInfoSave(10, false)
        let submitUser = await getUserInfo(Number(userId))
        let res = await submitEntityForm(Number(userId), submitUser)
      
        setSubmissionLoading(false)
        if(res && res.status === 201) {
            // successful submitted navigate back to login or stay
            setSubmissionOpen(true)
            setSubmissionSuccess(true)

        }
        else {
            // failure alert
            setSubmissionOpen(true)
            setSubmissionSuccess(false)
        }
    }
    if(user){
        return (
            <div className={s.container}>
                <div className={s.leftContainer}>
                {theme === 'dark' ?
                    <img className={s.headerImage} src={require('../../assets/SDM-dark.png')} alt="SDM" height={80} width='auto'/>
                    :<img className={s.headerImage} src={require('../../assets/SDM-light.png')} alt="SDM" height={80} width='auto'/>
                }
                    {isMobile? null: <div className={s.stepperContainer}>
                        {Object.keys(entitySteps).map((step, index) => (
                            <div 
                                key={index}
                                className={curStep === index ? cn(s.stepper, s.activeStepper):s.stepper}
                                onClick={() => handleStepperClick(step, index)}
                            >
                                <div className={cn(s.stepIcon)} style={{color: 'white', width: '25%'}}>{ 
                                    formCompletionStatus[step] ? 
                                    curStep === index ? <CheckCircleIcon color='success'/> : <CheckCircleOutlineIcon color='success'/>
                                    :(index ===3)? curStep === index ? <ErrorIcon sx={{color: 'grey'}}/> : <ErrorOutlineIcon sx={{color: 'grey'}}/>
                                    :curStep === index ? <ErrorIcon sx={{color: 'black'}}/> : <ErrorOutlineIcon color='warning'/>}</div>
                                <div className={theme === 'dark' ? 
                                    curStep === index ? cn(s.stepName, s.stepDark, s.activeStepper):cn(s.stepName, s.stepDark, s.stepper)
                                    :curStep === index ? cn(s.stepName, s.stepLight, s.activeStepper):cn(s.stepName, s.stepLight, s.stepper)}>
                                    {step}
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
                <div className={s.rightContainer}>
                    <div className={s.logout}>
                        <Button variant="monochrome" size="lg" onClick={handleLogOut}>
                            Log out
                        </Button>
                        <ThemeSwitchToggle />
                        {/* <IconButton sx={theme === 'dark' ? { ml: 1, color: 'white'} : { ml: 1, color: 'black'}} onClick={toggleTheme} color="inherit">
                            {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton> */}
                    </div>
                    <div className={s.headerSection}>
                        <div className={theme === 'dark' ? cn(s.darkFont, s.header) : cn(s.lightFont, s.header)}>{stepsArray[curStep]}</div>
                        <div className={s.buttons}>
                            {curStep === 9 ? null : <Button className={s.button} size="lg" onClick={async() => {await handlePagesInfoSave(curStep, true); handleVerification(curStep)}} disabled={submissionLoading}>
                                Save and Verify
                            </Button>}
                            {curStep === 0 ? null :
                            <Button className={s.button} size="lg" variant="brand" onClick={onPreviousPage} disabled={submissionLoading || pageLoading}>
                                Previous
                            </Button>}
                            {curStep === 10 ? <Button variant="brand" className={s.button} size="lg" onClick={handleSubmitApplication} disabled={submissionLoading || !readyToSubmit || pageLoading}>
                                {submissionLoading? 'Submitting ...':'Submit'}
                            </Button> : <Button variant="brand" className={s.button} size="lg" onClick={onNextPage} disabled={submissionLoading || pageLoading}>
                                Next
                            </Button>}
                            
                            
                        </div>
                    </div>
                    {curStep === 0 && <CompanyVerification companyVerification={companyVerification} setCompanyVerification={setCompanyVerification} invalidInputFields={invalidInputs}/>}
                    {curStep === 1 && <AdditionalInformation additionalInformation={additionalInformation} setAdditionalInformation={setAdditionalInformation} userPublicFolderLink={user.user_registration?.public_folder_url} userId={userId || ''} invalidInputFields={invalidInputs}/>}
                    {curStep === 2 && <TradingAccount tradingInformation={tradingInformation} setTradingInformation={setTradingInformation} invalidInputFields={invalidInputs} setInitialTradeFiatToCrypto={setInitialTradeFiatToCrypto}/>}
                    {curStep === 3 && <WalletAddress walletAddress={walletAddress} setWalletAddress={setWalletAddress}/>}
                    {curStep === 4 && <FinancialInformation financialInformation={financialInformation} setFinancialInformation={setFinancialInformation} invalidInputFields={invalidInputs} />}
                    {curStep === 5 && <BankingInformation bankingInformation={bankingInformation} setBankingInformation={setBankingInformation} invalidInputFields={invalidFormInputs}/>}
                    {curStep === 6 && <UploadDocuments uploadDocuments={uploadDocuments} setUploadDocuments={setUploadDocuments} legalEntityType={companyVerification.applicant_legal_type} userPublicFolderLink={user.user_registration?.public_folder_url} userId={userId || ''} isMSB={isMSB} initialTradeFiatToCrypto={initialTradeFiatToCrypto} setInitialTradeFiatToCrypto={setInitialTradeFiatToCrypto} setIsMSB={setIsMSB}/>}
                    {curStep === 7 && <UBOVerification uboVerification={uboVerification} setUboVerification={setUboVerification} invalidInputFields={invalidFormInputs}/>}
                    {curStep === 8 && <DirectorsAndAdditionalAuthorizedUsers directors={directors} setDirectors={setDirectors} authorizedUsers={authorizedUsers} setAuthorizedUsers={setAuthorizedUsers} invalidDirectors={invalidDirectorInputs} invalidAuthUsers={invalidAuthUserInputs}/>}
                    {curStep === 9 && <Declaration declaration={declaration} setDeclaration={setDeclaration}/>}
                    {curStep === 10 && <Referral referralInformation={referralInformation} setReferralInformation={setReferralInformation} invalidInputFields={invalidInputs}/>}
                    <div className={cn(s.footerSection, { [s.alignRight]: curStep === 0 })}>
                        <Button variant="brand" className={curStep === 0 ? cn(s.button, s.hide):s.button} size="lg" onClick={onPreviousPage} disabled={submissionLoading || pageLoading}>
                            Previous
                        </Button>
                        {curStep === 10 ? <Button variant="brand" className={s.button} size="lg" onClick={handleSubmitApplication} disabled={submissionLoading || !readyToSubmit || pageLoading}>
                            {submissionLoading? 'Submitting ...':'Submit'}
                        </Button> : <Button variant="brand" className={s.button} size="lg" onClick={onNextPage} disabled={submissionLoading || pageLoading}>
                            Next
                        </Button>}
                    </div>
                </div>
                <CornerToaster id={'VALIDATING'} header={'Missing Required Information'} message={validationMessage} error={true} show={openValidation && !validationSuccess} success={false} closeToast={() => setOpenValidation(false)} position={"bottom-left"}/>
                <CornerToaster id={'VALIDATING'} header={stepsArray[curStep]} message={'All information has been successfully saved.'} error={false} show={openValidation && validationSuccess} success={true} closeToast={() => setOpenValidation(false)} position={"bottom-left"}/>
                <Modal open={submissionOpen && submissionSuccess} setOpen={setSubmissionOpen} isSuccess={true} isFailed={false} header={'Application Details Enrolled Successfully.'} message={'We will get back to you soon.'} action={quitButton}/>
                <Modal open={submissionOpen && !submissionSuccess} setOpen={setSubmissionOpen} isSuccess={false} isFailed={true} header={'Network error.'} message={'Please try to submit your application again'}/>
            </div>
        );
    }
    else {
        return (<div className={s.container}></div>)
    }
}

export default Entity;