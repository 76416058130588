import React, { useEffect, useState, useRef } from 'react';
import s from './forms.module.scss';
import { TextField, DropdownInput, AutoCompleteSelect } from '../../../components/UILib';
import { ResidentialInformationData, ApplicationStepStatus } from '../../../components/Types';
import { individualResidentialFormRequired } from '../../../constants/requiredFields';
import { fieldValidatingHandler } from '../../../utils/validations';
import countries from '../../../constants/countries';
import states from '../../../constants/states';
import { useTheme } from '../../../contexts/ThemeContext';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
// const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''
import { loadGoogleScript } from '../../../utils/googleAddressAutoComplete';
interface ResidentialAddressProps {
    residentialInformation: ResidentialInformationData;
    setResidentialInformation: React.Dispatch<React.SetStateAction<ResidentialInformationData>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
    invalidInputFields: string[];
}
let countryList = countries.map(country => {return {value: country.id, label: country.name}})
// let statesList = states.map(state => {return {value: state.name, label: state.name}})
function ResidentialAddress({residentialInformation, setResidentialInformation, setFormCompletionStatus, invalidInputFields}: ResidentialAddressProps) {
    const autocompleteInput = useRef<HTMLInputElement>(null);
    const { theme } = useTheme();
    const [currentSelectedCountry, setCurrentSelectedCountry] = useState<AutoCompleteSelectDataType | null>(null);
    //const [currentSelectedState, setCurrentSelectedState] = useState<AutoCompleteSelectDataType | null>(null);
    useEffect(() => {
        // Load script and initialize or use existing Google Maps
        let script: any;
        if (window.google) {
            initAutocomplete();
        } else {
            script = loadGoogleScript();
        }
    
        // Cleanup function to remove script and possibly other cleanups
        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);
    const initAutocomplete = () => {
            
            
        if (!autocompleteInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput.current, {
            types: ["address"], // You can specify the type of place data to return.
        });
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
          
            let streetNumber: string = ''
            let streetAddress: string = ''
            let city: string = ''
            let country: string = ''
            let state: string = ''
            let postal: string = ''
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                // @ts-ignore remove once typings fixed
                const componentType = component.types[0];
                switch (componentType) {
                    case "street_number": {
                        streetNumber = `${component.long_name} `;
                        break;
                    }
                    case "route": {
                        streetAddress = streetNumber + component.short_name;
                        break;
                    }
                    case "postal_code": {
                        postal = component.long_name;
                        break;
                    }
                    case "locality":
                        city = component.long_name
                        break;
            
                    case "administrative_area_level_1": {
                        state = component.long_name
                        break;
                    }
            
                    case "country":
                        country = component.long_name
                        break;
                }
            }
            setResidentialInformation((prevState) => ({...prevState, street_address: streetAddress, postal_code: postal, city: city, state:state, country:country}))
        });

    };
    // useEffect(() => {
    //     const loadGoogleScript = () => {
    //         const script = document.createElement("script");
    //         script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`;
    //         script.async = true;
    //         script.onload = () => initAutocomplete();
    //         document.body.appendChild(script);
    //     };
    
    //     const initAutocomplete = () => {
            
            
    //         if (!autocompleteInput.current) return;
    //         const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput.current, {
    //             types: ["address"], // You can specify the type of place data to return.
    //         });
    //         autocomplete.addListener("place_changed", () => {
    //             const place = autocomplete.getPlace();
    //           
    //             let streetNumber: string = ''
    //             let streetAddress: string = ''
    //             let city: string = ''
    //             let country: string = ''
    //             let state: string = ''
    //             let postal: string = ''
    //             for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    //                 // @ts-ignore remove once typings fixed
    //                 const componentType = component.types[0];
    //                 switch (componentType) {
    //                     case "street_number": {
    //                         streetNumber = `${component.long_name} `;
    //                         break;
    //                     }
    //                     case "route": {
    //                         streetAddress = streetNumber + component.short_name;
    //                         break;
    //                     }
    //                     case "postal_code": {
    //                         postal = component.long_name;
    //                         break;
    //                     }
    //                     case "locality":
    //                         city = component.long_name
    //                         break;
                
    //                     case "administrative_area_level_1": {
    //                         state = component.long_name
    //                         break;
    //                     }
                
    //                     case "country":
    //                         country = component.long_name
    //                         break;
    //                 }
    //             }
    //             setResidentialInformation((prevState) => ({...prevState, street_address: streetAddress, postal_code: postal, city: city, state:state, country:country}))
    //         });

    //     };
    
    //     loadGoogleScript();
    // }, []);
    
    useEffect(() => {
      
    },[currentSelectedCountry])
    useEffect(() => {
        // get country code 
        if(residentialInformation.country){
            let country = countryList.filter(c => c.label === residentialInformation.country)[0]
            setCurrentSelectedCountry(country)
            // set states list based on country
        }
        // // update completion status
        // let result = fieldValidatingHandler(individualResidentialFormRequired, residentialInformation)
        // if(result.length === 0) {
        //     setFormCompletionStatus((prevState) => ({...prevState, 'Residential Address': true}))
        // }
        // else {
        //     setFormCompletionStatus((prevState) => ({...prevState, 'Residential Address': false}))
        // }
        // if(residentialInformation.state){
        //     let curState = {value: residentialInformation.state, label: residentialInformation.state}
        //     setCurrentSelectedState(curState)
        //     // set states list based on country
        // }
        
    },[residentialInformation])
    const handleInputValueChange = (value: string, name: string) => {
      
        setResidentialInformation((prevState) => ({...prevState, [name]: value}))
    }
    const hanleCountryChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
      
        setResidentialInformation((prevState) => ({...prevState, country: value.label}))
        setCurrentSelectedCountry({label: value.label, value: value.label})
    }
    // const hanleStateChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
    
    //     setResidentialInformation((prevState) => ({...prevState, state: value.label}))
    //     //setCurrentSelectedCountryId(e.target.value)
    // }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Street Address <sup>*</sup></p>
                        <TextField
                            ref={autocompleteInput}
                            name="street_address"
                            value={residentialInformation.street_address}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Street Address")}
                            error={invalidInputFields.includes("Street Address") ? 'Please enter your street address.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Unit</p>
                        <TextField
                            name="unit"
                            value={residentialInformation.unit}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>City <sup>*</sup></p>
                        <TextField
                            name="city"
                            value={residentialInformation.city}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("City")}
                            error={invalidInputFields.includes("City") ? 'Please enter your city.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={countryList}
                            value={currentSelectedCountry}
                            onChange={hanleCountryChange}
                            errorText={invalidInputFields.includes("Country")? 'Please select country.' : undefined}
                        />
                    </div>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>State <sup>*</sup></p>
                        {/* <AutoCompleteSelect
                            optionList={statesList}
                            value={currentSelectedState}
                            onChange={hanleStateChange}
                        /> */}
                        <TextField
                            name="state"
                            value={residentialInformation.state}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("State")}
                            error={invalidInputFields.includes("State") ? 'Please enter your state.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Postal Code <sup>*</sup></p>
                        <TextField
                            name="postal_code"
                            value={residentialInformation.postal_code}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Postal Code")}
                            error={invalidInputFields.includes("Postal Code") ? 'Please enter your postal code.' : undefined}
                        />
                    </div>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Phone Number <sup>*</sup></p>
                        <TextField
                            name="phone_number"
                            value={residentialInformation.phone_number}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Phone Number")}
                            error={invalidInputFields.includes("Phone Number") ? 'Please enter your phone number.' : undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResidentialAddress;