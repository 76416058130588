import React, { useEffect, useState, useRef, useCallback } from 'react';
import { entityDocumentTypes, entityDocumentType } from '../../../constants/entityDocumentTypes';
import { EntityDocumentsData } from '../../../components/Types';
import s from './forms.module.scss';
import { useDropzone } from 'react-dropzone';
import { uploadEntityFile } from '../../../api/entity';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '../../../contexts/ThemeContext';
import { RadioButtons } from '../../../components/UILib';
import DocumentDropZone from './DocumentDropZone';
import { flowOfFundsFromFiatToCrypto, moneyServiceBusiness } from '../../../constants/entityDocumentTypes';
import cn from 'classnames';
interface imageObj {
    name: string;
    image: any;
    imagePreview: any;
    type: string;
}
interface UploadDocumentsProps {
    uploadDocuments : EntityDocumentsData[];
    setUploadDocuments: React.Dispatch<React.SetStateAction<EntityDocumentsData[]>>;
    legalEntityType: string;
    userPublicFolderLink: string;
    userId: string;
    initialTradeFiatToCrypto: boolean;
    isMSB: boolean;
    setInitialTradeFiatToCrypto: React.Dispatch<React.SetStateAction<boolean>>;
    setIsMSB: React.Dispatch<React.SetStateAction<boolean>>;
}
const options = [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
function UploadDocuments({uploadDocuments, setUploadDocuments, legalEntityType, userId,userPublicFolderLink, initialTradeFiatToCrypto, isMSB, setInitialTradeFiatToCrypto, setIsMSB }: UploadDocumentsProps) {
    // TODO: BASED ON uploadDocuments, NEED TO FETCH THE LINK FOR THE UPLOADED DOCS
    const { theme } = useTheme();
    const [loadings, setLoadings] = useState<boolean[]>([]);
    const [initialTradeLoading, setInitialTradeLoading] = useState<boolean>(false);
    const [msbLoading, setMsbLoading] = useState<boolean[]>([]);
    const [warnings, setWarnings] = useState<(string | null)[]>([]);
    const [msbFileSizeWarning, setMsbFileSizeWarning] = useState<(string | null)[]>([]);
    // const hiddenUpdateFileInput = useRef<(HTMLDivElement | null)[]>([])
    const [uploadImages, setUploadImages] = useState<imageObj[]>([])
    useEffect(() => {
      
        const uniqueString = new Date().getTime();
        let docs = [...uploadDocuments]
        let docsArray:imageObj[] = docs.filter(doc => doc.type !== 'supplier_agreement').map(doc => {return {name: doc.type, image: null, imagePreview: doc.doc_link != ""? `https://drive.google.com/thumbnail?id=${extractFolderId(doc.doc_link)}&rand=${uniqueString}` : null, type: doc.type }})
        setUploadImages(docsArray)
        setLoadings(new Array(docsArray.length).fill(false))
        setWarnings(new Array(docsArray.length).fill(null))
        setMsbFileSizeWarning(new Array(2).fill(null))
    },[])
    // const handleClickUploadImages = (index: number) => {
    //     hiddenUpdateFileInput.current[index]?.click();
    // }
    const enterLoading = (index: number, type: string) => {
        if(type == Object.keys(flowOfFundsFromFiatToCrypto)[0]){
            setInitialTradeLoading(true)
        }
        else if (Object.keys(moneyServiceBusiness).includes(type)) {
            setMsbLoading(prevLoadings => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = true;
                return newLoadings;
            });
        }
        else {
            setLoadings(prevLoadings => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = true;
                return newLoadings;
            });
        }
    }
    const updateSizeWarnings = (index: number, type: string, warning: string | null) => {
        if (Object.keys(moneyServiceBusiness).includes(type)) {
            setMsbFileSizeWarning(prevWarnings => {
                const newWarnigs = [...prevWarnings];
                newWarnigs[index] = warning;
                return newWarnigs;
            });
        }
        else {
            setWarnings(prevWarnings => {
                const newWarnigs = [...prevWarnings];
                newWarnigs[index] = warning;
                return newWarnigs;
            });
        }
    }
    const exitLoading = (index: number, type: string) => {
        if(type == Object.keys(flowOfFundsFromFiatToCrypto)[0]){
            setInitialTradeLoading(false)
        }
        else if (Object.keys(moneyServiceBusiness).includes(type)) {
            setMsbLoading(prevLoadings => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }
        else {
            setLoadings(prevLoadings => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }
        
    }
    const extractFolderId = (url: string) => {
        const match = url.match(/https:\/\/drive\.google\.com\/file\/d\/([^\/]+)/);
        return match ? match[1] : null;
    };
    // const onDrop = useCallback(async (acceptedFiles: any[], index: number, type: string) => {
    //     enterLoading(index)
    //     const fileUploaded = acceptedFiles[0];
    //     let imageArr = [...uploadImages]
    //     if(index > uploadImages.length - 1) {
    //         // new image added
    //         let newImg = {} as imageObj
    //         newImg.name = fileUploaded.name
    //         newImg.type = type
    //         newImg.image = fileUploaded
    //         newImg.imagePreview = URL.createObjectURL(fileUploaded)
    //         imageArr.push(newImg)
    //         if (fileUploaded && userId !== '' && userPublicFolderLink) {
    //             let res = await uploadEntityFile(fileUploaded, userId, userPublicFolderLink, type);
    //             if(res) {
    //                 let previewLink = extractFolderId(res)
    //                 previewLink = `https://drive.google.com/thumbnail?id=${previewLink}`
    //                 newImg.imagePreview = previewLink;
    //                 setUploadDocuments((prevState) => ({...prevState, doc_link: res as string }));
    //             } else {
    //                 // todo: Handle error 
    //                 console.error('Failed to upload file');
    //             }
    //         } else {
    //             console.error('Missing user ID or folder link');
    //         }
    //     }
    //     else {
    //         imageArr[index].image = fileUploaded
    //         imageArr[index].imagePreview = URL.createObjectURL(fileUploaded)
    //         if (fileUploaded && userId !== '' && userPublicFolderLink) {
    //             let res = await uploadEntityFile(fileUploaded, userId, userPublicFolderLink, type);
    //             if(res) {
    //                 let previewLink = extractFolderId(res)
    //                 previewLink = `https://drive.google.com/thumbnail?id=${previewLink}`
    //                 imageArr[index].imagePreview = previewLink;
    //                 setUploadDocuments((prevState) => ({...prevState, doc_link: res as string }));
    //             } else {
    //                 // todo: Handle error 
    //                 console.error('Failed to upload file');
    //             }
    //         } else {
    //             console.error('Missing user ID or folder link');
    //         }
    //     }
    //     setUploadImages(imageArr);
    //     exitLoading(index);
    // }, [userId, userPublicFolderLink, setUploadDocuments]);
    // const handleChangeUpdateImage = (event: React.ChangeEvent<HTMLInputElement>, index: number, type: string) => {
    //     // setLoading(true)
    //     if (!event.target.files) {
    //         return;
    //     }
    //     let imageArr = [...uploadImages]
    //     const fileUploaded = event.target.files[0];
    //    
    //     if(index > uploadImages.length - 1) {
    //         // new image added
    //         let newImg = {} as imageObj
    //         newImg.name = fileUploaded.name
    //         newImg.type = type
    //         newImg.image = fileUploaded
    //         newImg.imagePreview = URL.createObjectURL(fileUploaded)
    //         imageArr.push(newImg)
    //     }
    //     else {
    //         imageArr[index].image = fileUploaded
    //         imageArr[index].imagePreview = URL.createObjectURL(fileUploaded)
    //     }
    //     setUploadImages(imageArr)
    // }
    const mergeDocuments = (currentDocs: EntityDocumentsData[], newDocs: EntityDocumentsData[]) => {
        // Map over current documents to update or retain existing entries
        const updatedDocs = currentDocs.map(doc => {
            // Find a new document that matches the type of the current document
            const matchingNewDoc = newDocs.find(newDoc => newDoc.type === doc.type);
            // If a match is found, update the doc_link, otherwise return the original
            return matchingNewDoc ? { ...doc, doc_link: matchingNewDoc.doc_link } : doc;
        });
    
        // Filter out newDocs that are already included in the updatedDocs
        const newDocsToAdd = newDocs.filter(newDoc => 
            !currentDocs.some(doc => doc.type === newDoc.type));
    
        // Combine the updated existing docs with any new docs that weren't already present
        return [...updatedDocs, ...newDocsToAdd];
    };
    const updateFileInState = useCallback(async(file: File, index: number, type: string) => {
        const fileSizeWarningMsg = "File size exceeds 20MB. Please upload a smaller file."
        enterLoading(index, type)
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB

      // Check if the file size exceeds 20MB
        if (fileSizeInMB > 20) {
            updateSizeWarnings(index, type, fileSizeWarningMsg)
            exitLoading(index, type);
            return
        }
        updateSizeWarnings(index, type, null)
        const newFileData = {
            name: file.name,
            imagePreview: URL.createObjectURL(file),
            type: type
        };
        const updatedFiles = [...uploadDocuments];
      
        
        if (file && userId !== '' && userPublicFolderLink) {
            let res = await uploadEntityFile(file, userId, userPublicFolderLink, type);
            if(res) {
                let previewLink = extractFolderId(res)
                previewLink = `https://drive.google.com/thumbnail?id=${previewLink}`
                //newFileData.imagePreview = previewLink;
                let docIndex : number
                if(type == Object.keys(flowOfFundsFromFiatToCrypto)[0]){
                    docIndex = updatedFiles.findIndex(doc => doc.type === type);
                }
                else if(Object.keys(moneyServiceBusiness).includes(type)){
                    docIndex = updatedFiles.findIndex(doc => doc.type === type);
                }
                else {
                    docIndex = index
                }
           
                
                updatedFiles[docIndex].type = type
                updatedFiles[docIndex].doc_link = res
                setUploadImages(prevItems => {
                    return prevItems.map((item, idx) => {
                        if (idx === docIndex) {
                            return { ...item, ...newFileData };
                        }
                        return item;
                    });
                });
                //setUploadDocuments((prevState) => ({...prevState, doc_link: res as string }));
            } else {
                // todo: Handle error 
                console.error('Failed to upload file');
            }
        } else {
            console.error('Missing user ID or folder link');
        }
      
        //updatedFiles[index] = newFileData;
        
        setUploadDocuments(updatedFiles);
        
        exitLoading(index, type);
    }, [uploadDocuments, setUploadDocuments]);

    const onSelectionInitialTradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
        const value = event.target.value === 'true';
        setInitialTradeFiatToCrypto(value);
    }

    const onSelectionMSBChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
        const value = event.target.value === 'true';
        setIsMSB(value);
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                {uploadImages.filter(doc => doc.type !== 'supplier_agreement' && !Object.keys(moneyServiceBusiness).includes(doc.type)).map((doc, index) => doc.type === Object.keys(flowOfFundsFromFiatToCrypto)[0] ? initialTradeFiatToCrypto ? (
                    <>
                        <DocumentDropZone
                            key={index}
                            type={doc.type}
                            existingFile={doc.imagePreview}
                            legalEntityType={legalEntityType}
                            loading={loadings[index]}
                            onFileAccepted={(acceptedFiles) => updateFileInState(acceptedFiles[0], index, doc.type)}
                        />
                        {warnings[index]? <div className={s.row}>
                            <span className={s.errorText}>{warnings[index]}</span>
                        </div>: null}
                    </>
                    
                ) : null 
                : (<>
                    <DocumentDropZone
                        key={index}
                        type={doc.type}
                        existingFile={doc.imagePreview}
                        legalEntityType={legalEntityType}
                        loading={loadings[index]}
                        onFileAccepted={(acceptedFiles) => updateFileInState(acceptedFiles[0], index, doc.type)}
                    />
                    {warnings[index]? 
                        <div className={s.row}>
                            <span className={s.errorText}>{warnings[index]}</span>
                        </div>: null}
                </>)
                )}
                {/* <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the flow of funds for the company's initial trade Fiat to Crypto?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionInitialTradeChange(e)} 
                            value={initialTradeFiatToCrypto}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',
                                    margin: 0,
                                }}
                        />
                    </div>
                </div> */}
                {/* {initialTradeFiatToCrypto ? 
                    <div className={s.row}>
                        {uploadImages.filter(doc => doc.type == Object.keys(flowOfFundsFromFiatToCrypto)[0]).map(doc => (<DocumentDropZone
                            type={doc.type}
                            existingFile={doc.imagePreview}
                            legalEntityType={legalEntityType}
                            loading={initialTradeLoading}
                            onFileAccepted={(acceptedFiles) => updateFileInState(acceptedFiles[0], 0, doc.type)}
                        />))}
                    </div> 
                : null} */}
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the company considered a Money Services Business (MSB)?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionMSBChange(e)} 
                            value={isMSB}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
                {isMSB ? 
                    <>
                        {uploadImages.filter(doc => Object.keys(moneyServiceBusiness).includes(doc.type)).map((doc, index) => 
                        (<>
                            <div className={s.row}>
                                <DocumentDropZone
                                    type={doc.type}
                                    existingFile={doc.imagePreview}
                                    legalEntityType={legalEntityType}
                                    loading={msbLoading[index]}
                                    onFileAccepted={(acceptedFiles) => updateFileInState(acceptedFiles[0], index, doc.type)}
                                />
                            </div>
                            {msbFileSizeWarning[index]? <div className={s.row}>
                            <span className={s.errorText}>{msbFileSizeWarning[index]}</span>
                        </div>: null}
                        </>))}
                        
                    </> 
                : null}
            </div>
        </div>
    );
    // return (
    //     <div className={s.rightContainer}>
    //         <div className={s.formContainer}>
    //             {uploadImages.map((uploadImage, index) => (<>
    //                 <div className={s.row}>
    //                     <div className={s.inputSectionFull}>
    //                         <p className={theme === 'dark' ? s.darkFont : s.lightFont}>{uploadImage.type}
    //                         {uploadImage.type == 'Additional file (optional)' ? null:
    //                         legalEntityType == 'Trust' && uploadImage.type == 'Original Certificate of Trust'? null:<sup>*</sup>}</p>
    //                         {uploadImage?.imagePreview? 
    //                         <div className={theme === 'dark'? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)} onClick={() => handleClickUploadImages(index)}>
    //                             <input
    //                                 type="file"
    //                                 ref={el => hiddenUpdateFileInput.current[index] = el}
    //                                 onChange={(e) => handleChangeUpdateImage(e, index, uploadImage.type)}
    //                                 style={{display: 'none'}}
    //                             />
    //                             <img
    //                                 className={theme === 'dark'? cn(s.image, s.darkFont) : cn(s.image, s.lightFont)}
    //                                 src={uploadImage.imagePreview} alt={uploadImage.type + ': ' + uploadImage.name}
    //                             />
                                
    //                         </div>
    //                         :<div className={theme === 'dark'? cn(s.imageUploader, s.dark) : cn(s.imageUploader, s.light)} onClick={() => handleClickUploadImages(index)}>
    //                             {loadings[index]? <CircularProgress className={s.loading}/>:null }
    //                             <div className={s.innerContainer}>
    //                                 <UploadFileIcon className={theme === 'dark'? cn(s.icon, s.darkFont) : cn(s.icon, s.lightFont)}/>
    //                                 {/* <UploadOutlined style={{ fontSize: '50px', marginBottom: '5px'}}/> */}
    //                                 <input
    //                                     type="file"
    //                                     ref={el => hiddenUpdateFileInput.current[index] = el}
    //                                     onChange={(e) => handleChangeUpdateImage(e, index, uploadImage.type)}
    //                                     style={{display: 'none'}}
    //                                 />
    //                                 <div className={theme === 'dark'? cn(s.description, s.darkFont) : cn(s.description, s.lightFont)}>Upload an Attachment</div>
    //                             </div>
    //                             <div className={theme === 'dark'? cn(s.subDescription, s.darkFont) : cn(s.subDescription, s.lightFont)}>{uploadImage.type}</div>
    //                         </div>}
    //                         {/* {uploadImage?.imagePreview? 
    //                             <div className={s.subDescription}>
    //                                 Upload 
    //                             </div> : null} */}
    //                     </div>
    //                 </div>
    //             </>))}
    //         </div>
    //     </div>
    // );
}

export default UploadDocuments;