import axios from 'axios';
import { 
    CompanyVerificationData,
    AdditionalInformationData,
    EntityTradingAccountData,
    EntityFinancialInformationData,
    BankingInformationData,
    DirectorData,
    UBOVerificationData,
    AdditionalAuthorizedUserData
} from '../components/Types';

const API_URL = process.env.REACT_APP_BACKEND_API_URL
const instance = axios.create({
    baseURL: API_URL 
})

export const saveCompanyVerificationInformation = async(userId: number, verification_information: CompanyVerificationData) => {
    try {
        const res = await instance.post('/entityVerificationInformation', {
            userId: userId,
            applicant_information: verification_information
        })
        return res
    }
    catch (err) {
        return false
    }
}
export const saveCompanyAdditionalInformation = async(userId: number, additional_information: AdditionalInformationData ) => {
    try {
        const res = await instance.post('/entityAdditionalInformation', {
            userId: userId,
            applicant_information: additional_information
        })
        return res
    }
    catch (err) {
        return false
    }
}
export const uploadEntityFile = async(file: File, userId: string, userFolderLink: string, fileName: string) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('userFolderLink', userFolderLink);
        formData.append('fileType', fileName)
        const response = await instance.post('/entityUploadFile', formData)
        if (response.status !== 201) {
            throw new Error('Failed to upload file');
        }

        const data = response.data
        return data;
    }
    catch (err) {
        return false
    }
}
export const saveTradingInformation = async(userId: number, trading_info: EntityTradingAccountData) => {
    try {
        const res = await instance.post('/entityTradingInformation', {
            userId: userId,
            applicant_information: trading_info
        })
        return res
    }
    catch (err) {
        return false
    }
}
export const saveFinancialInformation = async(userId: number, financial_info: EntityFinancialInformationData) => {
    try {
        const res = await instance.post('/entityFinancialInformation', {
            userId: userId,
            applicant_information: financial_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveBankingInformation = async(userId: number, banking_info: BankingInformationData[]) => {
    try {
        const res = await instance.post('/entityBankingInformation', {
            userId: userId,
            applicant_information: banking_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveDirectorInformation = async(userId: number, director_info: DirectorData[]) => {
    try {
        const res = await instance.post('/entityDirectorInformation', {
            userId: userId,
            applicant_information: director_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveUBOInformation = async(userId: number, ubo_info: UBOVerificationData[]) => {
    try {
        const res = await instance.post('/entityUBOInformation', {
            userId: userId,
            applicant_information: ubo_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveAuthUserInformation = async(userId: number, auth_users_info: AdditionalAuthorizedUserData[]) => {
    try {
        const res = await instance.post('/entityAuthUserInformation', {
            userId: userId,
            applicant_information: auth_users_info
        })
        return res
    }
    catch (err) {
        return false
    }
}

export const saveLegalDocAdditionalInformation = async(userId: number, initial_trade_fiat_to_crypto: boolean, is_msb: boolean  ) => {
    try {
        let data = {
            initial_trade_fiat_to_crypto: initial_trade_fiat_to_crypto,
            is_msb: is_msb
        }
        const res = await instance.post('/entityLegalDocInformation', {
            userId: userId,
            applicant_information: data
        })
        return res
    }
    catch (err) {
        return false
    }
}
export const submitEntityForm = async(userId: number, userData: any) => {
    
    try {
        let reqData = { completed: true }
        const res = await instance.post('/entitySubmission', {
            userId: userId,
            applicant_information: reqData,
            user_data: userData
        })
        return res
    }
    catch (err) {
        return false
    }
}

