import React, { useEffect, useState } from 'react';
import s from './BoardingForm.module.scss';
import { TextField, ThemeSwitchToggle, Button, RadioButtons, CornerToaster } from '../../components/UILib';
import { updateUserBoardingInfo } from '../../api/applicantion';
import { useTheme } from '../../contexts/ThemeContext';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { UserBoardingRequestDataType } from '../../components/Types/UserAuthRequestType';
interface ApplicationType {
    value: 'individual' | 'entity';
    label: 'Individual' | 'Entity';
  }
function BoardingForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const { loginEmail } = location.state || {};
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    // state
    const [name, setName] = useState<string>('');
    const { theme, toggleTheme } = useTheme();
    // TODO: NEED TO FETCH EMAIL VALUE FROM API
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [emailInputValid, setEmailInputValid] = useState(false);
    const [applicationType, setApplicationType] = useState<'entity' | 'individual'>('individual'); // for radioButtons
    // res status status
    const [error, setError] = useState<boolean>(false);
    const errorMessage = (
        <p className={theme === 'dark'? s.dark : s.light}>
            Failed to create user application, please try again later.
        </p>
    )
    const applicationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue === 'individual' || newValue === 'entity') {
            setApplicationType(newValue);
        } else {
            console.error('Invalid application type:', newValue);
            setApplicationType('individual')
            // Handle the error case, e.g., reset to a default value, show an error message, etc.
        }
    }
    const applicationTypes: ApplicationType[] = [{value: 'individual', label: 'Individual'}, {value: 'entity', label: 'Entity'}]
    useEffect(() => {
        setEmail(loginEmail)
        setEmailInputValid(true)
    }, [loginEmail])

    const handleBoardingFormSubmit = async() => {
        setLoading(true)
        if(userId) {
            let reqData: UserBoardingRequestDataType = {
                applicant_name: name,
                primary_contact_email: email,
                application_type: applicationType,
                user_id: Number(userId),
                completed_form: 0,
            }
            let res = await updateUserBoardingInfo(reqData.user_id, reqData)
            if(res) {
                setError(false)
                // redirect to the corresponding application
                navigate(`/${applicationType}?userId=${userId}`);
            }
            else {
                // create boarding user failed -> throw error
                setError(true)
            }
        }
        else {
            // throw error toastr for non exist user id
        
            setError(true)
        }
        setLoading(false)
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <p className={theme === 'dark'? s.dark : s.light}>Name <sup>*</sup></p>
                <div className={s.inputSection}>
                    <TextField
                        name="name"
                        value={name}
                        onChange={setName}
                        variant="xs"
                        type='text'
                        required={true}
                    />
                </div>
                <p className={theme === 'dark'? s.dark : s.light}>Email <sup>*</sup></p>
                <div className={s.inputSection}>
                    <TextField
                        name="email"
                        value={email}
                        onChange={setEmail}
                        variant="xs"
                        type='email'
                        onError={setEmailInputValid}
                    />
                </div>
                <div className={s.selectSection}>
                    <div>
                        <RadioButtons
                            width='100%'
                            optionList={applicationTypes} 
                            onChange={applicationTypeChange} 
                            value={applicationType}
                            containerStyle={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
                            radioContainerStyle={{
                                    borderWidth: '1px', 
                                    borderStyle: 'solid', 
                                    borderColor: theme === 'dark' ? 'white' : 'black', 
                                    borderRadius: '8px', 
                                    width: '40%', 
                                    color: theme === 'dark' ? 'white' : 'black',
                                    margin: 0
                                }}
                        />
                    </div>
                </div>
                <div className={s.buttonSection}>
                    <Button 
                        variant={theme === 'dark' ? "outline" : "brand"} 
                        className={s.button} size="lg" 
                        onClick={handleBoardingFormSubmit}
                        disabled={!userId || !email || !emailInputValid || !name || loading}
                    >
                        Trade With Us
                    </Button>
                </div>
            </div>
            <CornerToaster id={"boarding error"} message={errorMessage} error={true} show={error} success={false} closeToast={() => setError(false)} />
        </div>
    );
}

export default BoardingForm;