import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '../../../contexts/ThemeContext';

import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import s from './Dropdown.module.scss';

export interface AutoCompleteSelectDataType {
    label: string;
    value: string | number;
}
const CustomPaperDark = styled(Paper)(({ theme }) => ({
    backgroundColor: '#202020', // Custom background color
    color: 'white', // Custom text color
    '& .MuiAutocomplete-option': {
        '&:hover': {
            backgroundColor: '#004166', // Change hover color here
            color: 'white', // Change text color on hover if needed
        },
    },
}));

const CustomPaperLight = styled(Paper)(({ theme }) => ({
    backgroundColor: '#FAF9F6', // Custom background color
    color: 'black', // Custom text color
    '& .MuiAutocomplete-option': {
        '&:hover': {
            backgroundColor: '#f4f4f4', // Change hover color here
            color: 'black', // Change text color on hover if needed
        },
    },
}));

export interface AutoCompleteSelectProps {
    // radioGroupLabel?: string;
    optionList: AutoCompleteSelectDataType[];
    onChange:(event: React.SyntheticEvent, value: AutoCompleteSelectDataType) => void;
    value: AutoCompleteSelectDataType | null;
    size?: 'small' | 'medium';
    width?: string;
    maxWidth? : string;
    disabled?: boolean;
    errorText?: string | undefined;
    currency?: boolean;
    freeInput?: boolean;
    placeholder?: string;
    disableClearable?: boolean;
    // containerStyle?: any;
    // radioContainerStyle?: any;
}
const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
    optionList,
    width = '100%',
    maxWidth = 'fit-content',
    value,
    size = 'small',
    errorText,
    onChange,
    disabled=false,
    currency=false,
    freeInput = false,
    placeholder = '',
}: AutoCompleteSelectProps) => {
    const { theme } = useTheme();
    const [open, setOpen] = useState(false);

    return (
        <Autocomplete<AutoCompleteSelectDataType, false, false, boolean>
            
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            size={size}
            value={value}
            freeSolo={freeInput}
            disabled={disabled}
            onInputChange={(event, newInputValue) => {
                if (freeInput && newInputValue !== '' && !optionList.some(option => option.label === newInputValue)) {
                  
                    onChange(event, { label: newInputValue, value: newInputValue });
                }
            
            }}
            onChange={(event, newValue, reason) => {
               
                if(newValue) {
                    if (typeof newValue === 'string') {
                        onChange(event, { label: newValue, value: newValue });
                    } else {
                        onChange(event, newValue);
                    }
                }
                else {
                    onChange(event, { label: '', value: '' });
                }
            }}
            options={optionList}
            //getOptionLabel={(option) => option.label}
            //isOptionEqualToValue={(option, value) => option.value === value.value}
            PaperComponent={theme === 'dark' ? CustomPaperDark : CustomPaperLight}
            renderOption={(props, option) => (
                <li {...props}>
                    <span style={{
                        maxWidth: '95%', // Adjust based on the actual width of your menu
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{option.label}</span>
                </li>
            )}
            sx={{
                width: width,
                // maxWidth: maxWidth,
                // minWidth: width,
                '& .MuiAutocomplete-option': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                '& .MuiInputBase-input': theme === 'dark' ? {
                    color: 'white',
                    fontFamily: "'Avenir', sans-serif",
                } : {
                    color: 'black',
                    fontFamily: "'Avenir', sans-serif",
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#4d4d4d',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        height: '42px'
                    },
                    '&:hover fieldset': {
                        borderColor: '#898989',
                        borderWidth: '2px',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#0676ED',
                        borderWidth: '2px',
                    },
                    '&.Mui-error fieldset': {
                        borderColor: '#f33',
                        borderWidth: '2px',
                    },
                },
                '& .MuiSvgIcon-root': {
                    color: '#4d4d4d',
                    width: '1.5em',
                },
                backgroundColor: 'transparent',
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={!!errorText}
                    helperText={currency? undefined : errorText}
                    placeholder={placeholder}
                    sx= {{
                        "& input::placeholder": {
                            fontSize: "0.7rem", // Change the font size
                        },
                    }}
                    FormHelperTextProps={{
                        style: {
                          color: '#f33', // Change the color
                          fontFamily: 'Avenir, sans-serif', // Change the font family
                        }
                    }}
                />
            )}
        />)
}
export default AutoCompleteSelect;
