import React from 'react';
import s from './forms.module.scss'
import { WalletAddressData } from '../../../components/Types';
import { AutoCompleteSelect, TextField, Button } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import { currencies } from '../../../constants/currencies';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import cn from 'classnames';
import { useTheme } from '../../../contexts/ThemeContext';
interface WalletAddressProps {
    walletAddress: WalletAddressData[];
    setWalletAddress: React.Dispatch<React.SetStateAction<WalletAddressData[]>>;
}

function WalletAddress({walletAddress, setWalletAddress}: WalletAddressProps) {
    const { theme } = useTheme();
    const handleWalletAddressChange = (value: string, index: number) => {
       
        const updatedWallet = walletAddress.map((wallet, idx) => {
            if (idx === index) {
                return {...wallet, wallet_address: value};
            }
            return wallet;
        });
        setWalletAddress(updatedWallet)
    }
    const handleCurrencyTypeChange = (selection: AutoCompleteSelectDataType, index: number) => {
       
        const updatedWallet = walletAddress.map((wallet, idx) => {
            if (idx === index) {
                return {...wallet, type: selection.value as string};
            }
            return wallet;
        });
        setWalletAddress(updatedWallet)
    }
    const handleAddNewWallet = () => {
        let wallet : WalletAddressData = {
            type: '',
            wallet_address: '',
        }
        let updatedWallet = [...walletAddress]
        updatedWallet.push(wallet)
        setWalletAddress(updatedWallet);
    }
    const removeNextWallet = (index: number) => {
        const updatedWallet = [...walletAddress]
        if (index + 1 < updatedWallet.length) {
            // Remove 1 element at position index + 1
            updatedWallet.splice(index + 1, 1);
        }
        setWalletAddress(updatedWallet);
    }
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Please enter your wallet addresses below.</p>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>We will need a wallet address to ultimately approve an application. If you need assistance in setting up a wallet we can provide our advice. You can skip this section if it is not available at the moment.</p>
                    </div>
                </div>
                {walletAddress.map((address, index) => 
                <div className={cn(s.row, s.addMargin)}>
                    <div className={s.inputSectionFull}>
                        <div className={s.currencySelectContainer}>
                            <div className={s.currencyWallet}>
                                <AutoCompleteSelect
                                    optionList={currencies}
                                    value={{value: address.type, label: address.type}}
                                    onChange={(e, value) => handleCurrencyTypeChange(value, index)}
                                    freeInput={true}
                                    placeholder='Select or enter custom asset'
                                />
                            </div>
                            <TextField
                                name="wallet_address"
                                value={address.wallet_address}
                                onChange={(value) => handleWalletAddressChange(value, index)}
                                variant="xs"
                                required={false}
                            />
                        </div>
                        {/* <p className={theme === 'dark' ? cn(s.darkFont, s.smallerFont, s.addLessMargin) : cn(s.lightFont,s.smallerFont, s.addLessMargin) }>Select or enter custom asset</p> */}
                        <div className={cn(s.addMoreMargin, s.toRight)}>
                            {walletAddress[index + 1] ? 
                            <Button
                                leftElement={<RemoveIcon />}
                                danger
                                className={s.button}
                                size="xs"
                                onClick={() => removeNextWallet(index)}
                            >
                                Remove Wallet
                            </Button>
                            :<Button
                                leftElement={<AddIcon />}
                                className={s.button}
                                size="xs"
                                onClick={handleAddNewWallet}
                            >
                                Add Wallet
                            </Button>}
                        </div>
                        
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default WalletAddress;