import React, { useState, useRef, useEffect } from 'react';
import s from './forms.module.scss';
import cn from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Divider from '@mui/material/Divider';
import { AutoCompleteSelect, TextField, Checkbox, Button, Icon } from '../../../components/UILib';
import { fiatCurrencies } from '../../../constants/fiatCurrencies';
import countries from '../../../constants/countries';
import { BankingInformationData } from '../../../components/Types';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import { useTheme } from '../../../contexts/ThemeContext';
import { loadGoogleScript } from '../../../utils/googleAddressAutoComplete';
interface bankInfo {
    index: number;
    bankInformation: BankingInformationData[];
    setBankingInformation: React.Dispatch<React.SetStateAction<BankingInformationData[]>>;
    invalidInputFields: string[];
}
let countryList = countries.map(country => {return {value: country.name, label: country.name}})

function BankingForm({index, bankInformation, setBankingInformation, invalidInputFields }: bankInfo) {
    const { theme } = useTheme();
    const autocompleteBankAddress = useRef<HTMLInputElement>(null);
    const autocompleteBeneficiaryAddress = useRef<HTMLInputElement>(null);
    const autocompleteIntermediaryBankAddress = useRef<HTMLInputElement>(null);
    useEffect(() => {
        // Load script and initialize or use existing Google Maps
        let script: any;
        if (window.google) {
            initAutocompleteBankAddress();
            initAutocompleteBeneficiaryAddress();
            if (bankInformation[index].has_intermediary_bank) {
                initAutocompleteIntermediaryBankAddress(); // Only initialize this if the state allows
            }
        } else {
            script = loadGoogleScript();
        }
    
        // Cleanup function to remove script and possibly other cleanups
        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, [bankInformation[index]]);

    const initAutocompleteBankAddress = () => {
        try {
            if (!autocompleteBankAddress.current) return;
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteBankAddress.current, {
                types: ["address"], // You can specify the type of place data to return.
            });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
               
                let streetNumber: string = ''
                let streetAddress: string = ''
                let city: string = ''
                let country: string = ''
                let state: string = ''
                let postal: string = ''
                for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            streetNumber = `${component.long_name} `;
                            break;
                        }
                        case "route": {
                            streetAddress = streetNumber + component.short_name;
                            break;
                        }
                        case "postal_code": {
                            postal = component.long_name;
                            break;
                        }
                        case "locality":
                            city = component.long_name
                            break;
                
                        case "administrative_area_level_1": {
                            state = component.long_name
                            break;
                        }
                
                        case "country":
                            country = component.long_name
                            break;
                    }
                }
                let value = streetAddress + (city? ', ' : '') + city + ', ' + state
                const updatedAccounts = bankInformation.map((account, idx) => {
                    if (idx === index) {
                        return {...account, bank_address: value, country: country};
                    }
                    return account;
                });
                setBankingInformation(updatedAccounts)
                //setAdditionalInformation((prevState) => ({...prevState, registered_address: streetAddress, registered_postal_code: postal, registered_city: city, registered_state:state, registered_country:country}))
            });
        }
        catch (err) {
          
        }
    }
    const initAutocompleteBeneficiaryAddress = () => {
        try {
            if (!autocompleteBeneficiaryAddress.current) return;
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteBeneficiaryAddress.current, {
                types: ["address"], // You can specify the type of place data to return.
            });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
              
                let streetNumber: string = ''
                let streetAddress: string = ''
                let city: string = ''
                let country: string = ''
                let state: string = ''
                let postal: string = ''
                for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            streetNumber = `${component.long_name} `;
                            break;
                        }
                        case "route": {
                            streetAddress = streetNumber + component.short_name;
                            break;
                        }
                        case "postal_code": {
                            postal = component.long_name;
                            break;
                        }
                        case "locality":
                            city = component.long_name
                            break;
                
                        case "administrative_area_level_1": {
                            state = component.long_name
                            break;
                        }
                
                        case "country":
                            country = component.long_name
                            break;
                    }
                }
                let value = streetAddress + (city? ', ' : '') + city + (state ? ', ' : '') + state + (country ? ', ' : '') + country
                const updatedAccounts = bankInformation.map((account, idx) => {
                    if (idx === index) {
                        return {...account, beneficiary_address: value};
                    }
                    return account;
                });
                setBankingInformation(updatedAccounts)
                //setAdditionalInformation((prevState) => ({...prevState, registered_address: streetAddress, registered_postal_code: postal, registered_city: city, registered_state:state, registered_country:country}))
            });
        }
        catch (err) {
        
        }
    }
    const initAutocompleteIntermediaryBankAddress = () => {
        try {
            if (!autocompleteIntermediaryBankAddress.current) return;
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteIntermediaryBankAddress.current, {
                types: ["address"], // You can specify the type of place data to return.
            });
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
              
                let streetNumber: string = ''
                let streetAddress: string = ''
                let city: string = ''
                let country: string = ''
                let state: string = ''
                let postal: string = ''
                for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "street_number": {
                            streetNumber = `${component.long_name} `;
                            break;
                        }
                        case "route": {
                            streetAddress = streetNumber + component.short_name;
                            break;
                        }
                        case "postal_code": {
                            postal = component.long_name;
                            break;
                        }
                        case "locality":
                            city = component.long_name
                            break;
                
                        case "administrative_area_level_1": {
                            state = component.long_name
                            break;
                        }
                
                        case "country":
                            country = component.long_name
                            break;
                    }
                }
                let value = streetAddress + (city? ', ' : '') + city + (state ? ', ' : '') + state + (country ? ', ' : '') + country
                const updatedAccounts = bankInformation.map((account, idx) => {
                    if (idx === index) {
                        return {...account, intermediary_bank_address: value};
                    }
                    return account;
                });
                setBankingInformation(updatedAccounts)
                //setAdditionalInformation((prevState) => ({...prevState, registered_address: streetAddress, registered_postal_code: postal, registered_city: city, registered_state:state, registered_country:country}))
            });
        }
        catch (err) {
          
        }
    }
    const handleSelectionValueChange = (selection: AutoCompleteSelectDataType, name: string) => {
       
        const updatedAccounts = bankInformation.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: selection.value };
            }
            return account;
        });
        setBankingInformation(updatedAccounts)
    }
    const handleInputValueChange = (value: string, name: string) => {
        
        const updatedAccounts = bankInformation.map((account, idx) => {
            if (idx === index) {
                return {...account, [name]: value };
            }
            return account;
        });
        setBankingInformation(updatedAccounts)
    }
    const handleIntermediaryCheckboxChange = () => {
        const updatedAccounts = bankInformation.map((account, idx) => {
            if (idx === index) {
                return {...account, has_intermediary_bank: !account.has_intermediary_bank };
            }
            return account;
        });
        setBankingInformation(updatedAccounts);
    }
    const addNextBank = () => {
        let newBank : BankingInformationData = {
            bank_account_name: '',
            account_number: '',
            routing_code:'',
            swift_code: '',
            beneficiary_address: '',
            bank_name: '',
            bank_address: '',
            country: '',
            account_currency: '',
            has_intermediary_bank: false,
            intermediary_bank_name: '',
            intermediary_routing_number: '',
            intermediary_bank_address: '',
        }
        let updatedAccounts = [...bankInformation]
        updatedAccounts.push(newBank)
        setBankingInformation(updatedAccounts);
    }
    const removeNextBank = () => {
        const updatedAccounts = [...bankInformation]
        if (index + 1 < updatedAccounts.length) {
            // Remove 1 element at position index + 1
            updatedAccounts.splice(index + 1, 1);
        }
        setBankingInformation(updatedAccounts);
    }
    return (
        <div className={s.formContainer}>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Account Currency <sup>*</sup></p>
                    <AutoCompleteSelect
                        optionList={fiatCurrencies}
                        value={{value: bankInformation[index].account_currency, label: bankInformation[index].account_currency}}
                        onChange={(e, value) => handleSelectionValueChange(value, 'account_currency')}
                        errorText={invalidInputFields && invalidInputFields.includes("Account Currency")? 'Please enter valid account currency.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Name on Bank Account <sup>*</sup></p>
                    <TextField
                        name="bank_account_name"
                        value={bankInformation[index].bank_account_name}
                        onChange={handleInputValueChange}
                        variant="xs"
                        error={invalidInputFields && invalidInputFields.includes("Name on Bank Account") ? 'Please enter a valid bank account name.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>IBAN/Account Number* <sup>*</sup></p>
                    <TextField
                        name="account_number"
                        value={bankInformation[index].account_number}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields && invalidInputFields.includes("IBAN/Account Number")}
                        error={invalidInputFields && invalidInputFields.includes("IBAN/Account Number") ? 'Please enter a valid IBAN/Account Number.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Routing Code <sup>*</sup></p>
                    <TextField
                        name="routing_code"
                        value={bankInformation[index].routing_code}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields && invalidInputFields.includes("Routing Code")}
                        error={invalidInputFields && invalidInputFields.includes("Routing Code") ? 'Please enter a valid routing code.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Swift/ABA/Sort Code <sup>*</sup></p>
                    <TextField
                        name="swift_code"
                        value={bankInformation[index].swift_code}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields &&  invalidInputFields.includes("Swift/ABA/Sort Code")}
                        error={invalidInputFields &&  invalidInputFields.includes("Swift/ABA/Sort Code") ? 'Please enter a valid Swift/ABA/Sort code.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Beneficiary Address <sup>*</sup></p>
                    <TextField
                        ref={autocompleteBeneficiaryAddress}
                        name="beneficiary_address"
                        value={bankInformation[index].beneficiary_address}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields &&  invalidInputFields.includes("Beneficiary Address")}
                        error={invalidInputFields &&  invalidInputFields.includes("Beneficiary Address") ? 'Please enter beneficiary address.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Bank Name <sup>*</sup></p>
                    <TextField
                        name="bank_name"
                        value={bankInformation[index].bank_name}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields &&  invalidInputFields.includes("Bank Name")}
                        error={invalidInputFields &&  invalidInputFields.includes("Bank Name") ? 'Please enter the name of your bank.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Bank Address <sup>*</sup></p>
                    <TextField
                        ref={autocompleteBankAddress}
                        name="bank_address"
                        value={bankInformation[index].bank_address}
                        onChange={handleInputValueChange}
                        variant="xs"
                        externalError={invalidInputFields &&  invalidInputFields.includes("Bank Address")}
                        error={invalidInputFields &&  invalidInputFields.includes("Bank Address") ? 'Please enter the address of your bank.' : undefined}
                    />
                </div>
            </div>
            <div className={s.row}>
                <div className={s.inputSectionFull}>
                    <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country <sup>*</sup></p>
                    <AutoCompleteSelect
                        optionList={countryList}
                        value={{value: bankInformation[index].country, label: bankInformation[index].country}}
                        onChange={(e, value) => handleSelectionValueChange(value, 'country')}
                        errorText={invalidInputFields && invalidInputFields.includes("Country")? 'Please select the country of your bank.' : undefined}
                    />
                </div>
            </div>
            <div className={cn(s.checkboxGrid, s.addMargin)}>
                <div className={theme === 'dark' ? cn(s.darkFont,s.checkbox) : cn(s.lightFont, s.checkbox)}>
                    <Checkbox onChange={() => handleIntermediaryCheckboxChange()} checked={bankInformation[index].has_intermediary_bank} />
                    If the sending/receiving bank account is located outside of The United States of America, please input intermediary bank details below:
                </div>
            </div>
            {bankInformation[index].has_intermediary_bank ? 
            <>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Intermediary Bank Name <sup>*</sup></p>
                        <TextField
                            name="intermediary_bank_name"
                            value={bankInformation[index].intermediary_bank_name}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields && invalidInputFields.includes("Intermediary Bank Name")}
                            error={invalidInputFields && invalidInputFields.includes("Intermediary Bank Name") ? 'Please enter your intermediary bank name.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Intermediary Routing Number <sup>*</sup></p>
                        <TextField
                            name="intermediary_routing_number"
                            value={bankInformation[index].intermediary_routing_number}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields && invalidInputFields.includes("Intermediary Routing Number")}
                            error={invalidInputFields && invalidInputFields.includes("Intermediary Routing Number") ? 'Please enter your intermediary bank routing number.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Intermediary Bank Address <sup>*</sup></p>
                        <TextField
                            ref={autocompleteIntermediaryBankAddress}
                            name="intermediary_bank_address"
                            value={bankInformation[index].intermediary_bank_address}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields && invalidInputFields.includes("Intermediary Bank Address")}
                            error={invalidInputFields && invalidInputFields.includes("Intermediary Bank Address") ? 'Please enter your intermediary bank address.' : undefined}
                        />
                    </div>
                </div>
            </>
            :null}
            <div className={s.row}>
                <Divider className={theme === 'dark'? s.sectionDividerDark : s.sectionDividerLight }/>
            </div>
            
            <div className={cn(s.row, s.rowToEnd, s.addMargin)}>
                {bankInformation[index + 1] ? 
                    <Button
                        leftElement={<RemoveIcon sx={theme === 'dark' ?{color: 'white'} : {color: 'black'}}/>}
                        className={s.button}
                        size="sm"
                        danger
                        onClick={removeNextBank}
                    >
                        Remove Bank Account
                    </Button>
                    :<Button
                        leftElement={<AddIcon sx={theme === 'dark' ?{color: 'white'} : {color: 'black'}}/>}
                        className={s.button}
                        size="sm"
                        onClick={addNextBank}
                    >
                        Add Bank Account
                    </Button>}
            </div>
        </div>
    );
}

export default BankingForm;