import React, { useEffect } from 'react';
import { FinancialInformationData, ApplicationStepStatus } from '../../../components/Types';
import s from './forms.module.scss';
import { fiatCurrencies } from '../../../constants/fiatCurrencies';
import { useTheme } from '../../../contexts/ThemeContext';
import { AutoCompleteSelect, TextField, RadioButtons } from '../../../components/UILib';
import { fieldValidatingHandler } from '../../../utils/validations';
import { individualFinancialFormRequired } from '../../../constants/requiredFields';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
interface FinancialInformationProps {
    financialInformation: FinancialInformationData;
    setFinancialInformation: React.Dispatch<React.SetStateAction<FinancialInformationData>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
    invalidInputFields: string[];
}
const options = [{value: true, label: 'Yes'}, {value: false, label: 'No'}]
function FinancialInformation({financialInformation, setFinancialInformation, setFormCompletionStatus, invalidInputFields}: FinancialInformationProps) {
    const { theme } = useTheme();
    const handleInputValueChange = (value: string, name: string) => {
       
        setFinancialInformation((prevState) => ({...prevState, [name]: value}))
    }
    const handleCurrencyValueChange = (selection: AutoCompleteSelectDataType, name: string) => {
      
        setFinancialInformation((prevState) => ({...prevState, [name]: selection.value}))
    }
    const onSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
      
        const value = event.target.value === 'true';
        setFinancialInformation((prevState) => ({...prevState, [name]: value}));
    }
    // useEffect(() => {
    //     // update completion status
    //     let result = fieldValidatingHandler(individualFinancialFormRequired, financialInformation)
    //     if(result.length === 0) {
    //         setFormCompletionStatus((prevState) => ({...prevState, 'Financial Information': true}))
    //     }
    //     else {
    //         setFormCompletionStatus((prevState) => ({...prevState, 'Financial Information': false}))
    //     }
    // }, [financialInformation])
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter the estimated annual income of the applicant. <sup>*</sup></p>
                        <div className={s.currencySelectContainer}>
                            <div className={s.currency}>
                                <AutoCompleteSelect
                                    optionList={fiatCurrencies}
                                    value={{value: financialInformation.income_currency, label: financialInformation.income_currency}}
                                    onChange={(e, value) => handleCurrencyValueChange(value, 'income_currency')}
                                    currency={true}
                                    errorText={invalidInputFields.includes("Estimated annual income currency")? 'Estimated annual income currency' : undefined}
                                />
                            </div>
                            <TextField
                                name="income"
                                value={financialInformation.income}
                                onChange={handleInputValueChange}
                                variant="xs"
                                type='number'
                                amount={true}
                                externalError={invalidInputFields.includes("Estimated annual income")}
                                error={invalidInputFields.includes("Estimated annual income") ? 'Please enter your estimated annual income.' : undefined}
                                leftElementClassName={s.currencySymbol}
                                leftElement={financialInformation.income_currency !== '' ? <span>{financialInformation.income_currency === 'CAD' || financialInformation.income_currency === 'USD' ? '$'
                                : financialInformation.income_currency === 'GBP' ? '£' : '€'}</span> : null}
                            />
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Enter the value of the liquid assets of the applicant. <sup>*</sup></p>
                        <div className={s.currencySelectContainer}>
                            <div className={s.currency}>
                                <AutoCompleteSelect
                                    optionList={fiatCurrencies}
                                    value={{value: financialInformation.liquid_asset_currency, label: financialInformation.liquid_asset_currency}}
                                    onChange={(e, value) => handleCurrencyValueChange(value, 'liquid_asset_currency')}
                                    currency={true}
                                    errorText={invalidInputFields.includes("Currency of the liquid assets")? 'Currency of the liquid assets' : undefined}
                                />
                            </div>
                            <TextField
                                name="liquid_asset_value"
                                value={financialInformation.liquid_asset_value}
                                onChange={handleInputValueChange}
                                variant="xs"
                                type='number'
                                amount={true}
                                externalError={invalidInputFields.includes("Value of the liquid assets")}
                                error={invalidInputFields.includes("Value of the liquid assets") ? 'Please enter the value of your liquid assets.' : undefined}
                                leftElementClassName={s.currencySymbol}
                                leftElement={financialInformation.liquid_asset_currency !== '' ? <span>{financialInformation.liquid_asset_currency === 'CAD' || financialInformation.liquid_asset_currency === 'USD' ? '$'
                                : financialInformation.liquid_asset_currency === 'GBP' ? '£' : '€'}</span> : null}
                            />
                        </div>
                    </div>
                </div>

                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Has the applicant ever declared bankruptcy? If so, please explain further.</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'bankruptcy')} 
                            value={financialInformation.bankruptcy}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: 'white', 
                                    margin: 0,
                                }}
                        />
                        {financialInformation.bankruptcy ? <TextField
                            name="bankruptcy_details"
                            value={financialInformation.bankruptcy_details}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />:null}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Is the applicant a politically exposed person ("PEP")? If so, please explain further.</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'pep')} 
                            value={financialInformation.pep}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: 'white', 
                                    margin: 0,
                                }}
                        />
                        {financialInformation.pep? <TextField
                            name="pep_details"
                            value={financialInformation.pep_details}
                            onChange={handleInputValueChange}
                            variant="xs"
                        />:null}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Over the previous four quarters, has the applicant maintained an average of at least 10 considerable-sized transactions per quarter in the relevant market?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'significant_transactions')} 
                            value={financialInformation.significant_transactions}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: 'white', 
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionFull}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Does the applicant have an investment portfolio (Cash and Financial Instruments) in the excess of USD 500,000?</p>
                        <RadioButtons
                            width='100%'
                            optionList={options} 
                            onChange={(e) => onSelectionChange(e, 'large_investment_portfolio')} 
                            value={financialInformation.large_investment_portfolio}
                            containerStyle={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                            radioContainerStyle={{
                                    width: '40%', 
                                    color: 'white', 
                                    margin: 0,
                                }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinancialInformation;