import React, { useState, useEffect } from 'react';
import s from './KYC.module.scss'
import cn from 'classnames';
import { getKYCAccessToken } from '../../api/applicantion';
import { useLocation } from 'react-router-dom';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Button } from '../../components/UILib';
function KYC() {
    const [accessToken, setAccessToken] = useState<string>('');
    const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
    const location = useLocation();
    const [update, setUpdate] = useState(0);
    const [loading, setLoading] = useState(true)
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get('email');
    useEffect(() => {
        if(user) {
          
            getAccessToken(user)
        }
    }, [user, update])
    const refreshToGetAccessToken = async() => {
        if(user) {
            
            // let token = await getKYCAccessToken(user)
            // token = token.token
            // setAccessToken(token);
            let count = update + 1
            setUpdate(count)
            setShowRefreshButton(false)
        }
    }
    const getAccessToken =async(user: string) => {
        let token = await getKYCAccessToken(user)
        token = token.token
        setAccessToken(token);
        setShowRefreshButton(false)
    }
    const accessTokenExpirationHandler = async(message: any) => {
      
        setShowRefreshButton(true)
    }
    return (
        <div className={s.rightContainer}>
            {showRefreshButton ? <div className={s.formContainer} style={{paddingTop:'10%'}}>
                <div className={s.rowToCenter}>
                    <Button variant="outline" className={s.button} size="lg" onClick={refreshToGetAccessToken}>Refresh KYC</Button>
                </div>
            </div> : null}
            <div>
                {accessToken && <SumsubWebSdk
                    accessToken={accessToken}
                    expirationHandler={accessTokenExpirationHandler}
                    config={{
                        lang: 'en',
                        // theme: theme
                    }}
                />}
            </div>
            
        </div>
    );
}

export default KYC;