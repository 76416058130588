import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import s from './forms.module.scss';
import { individualApplicantFormRequired } from '../../../constants/requiredFields';
import { ApplicationInformationData, ApplicationStepStatus } from '../../../components/Types';
import { fieldValidatingHandler } from '../../../utils/validations';
import { DropdownInput, TextField, DatePicker, AutoCompleteSelect } from '../../../components/UILib';
import { AutoCompleteSelectDataType } from '../../../components/UILib/AutoCompleteSelect/AutoCompleteSelect';
import occupations from './occupations';
import countries from '../../../constants/countries';
import { useTheme } from '../../../contexts/ThemeContext';
interface ApplicationInformationProps {
    applicationInformation: ApplicationInformationData;
    setApplicationInformation: React.Dispatch<React.SetStateAction<ApplicationInformationData>>;
    setFormCompletionStatus: React.Dispatch<React.SetStateAction<ApplicationStepStatus>>;
    invalidInputFields: string[];
}
let countryList = countries.map(country => {return {value: country.name, label: country.name}})
function ApplicationInformation({applicationInformation, setApplicationInformation, setFormCompletionStatus, invalidInputFields} : ApplicationInformationProps) {
    const { theme, toggleTheme } = useTheme();
    const [title, setTitle] = useState<AutoCompleteSelectDataType | null>(null);
    const [currentSelectedCountry, setCurrentSelectedCountry] = useState<AutoCompleteSelectDataType | null>(null);
  
    useEffect(() => {
        // get country code 
        if(applicationInformation.title){
            let occupation = {label: applicationInformation.title, value: applicationInformation.title}
            setTitle(occupation)
        }
        if(applicationInformation.country){
            let country = countryList.filter(c => c.label === applicationInformation.country)[0]
            setCurrentSelectedCountry(country)
        }
        // // update completion status
        // let result = fieldValidatingHandler(individualApplicantFormRequired, applicationInformation)
        // if(result.length === 0) {
        //     setFormCompletionStatus((prevState) => ({...prevState, 'Application Information': true}))
        // }
        // else {
        //     setFormCompletionStatus((prevState) => ({...prevState, 'Application Information': false}))
        // }

    },[applicationInformation])
    // const hanleTitleChange = (e: SelectChangeEvent) => {
  
    //     setApplicationInformation((prevState) => ({...prevState, title: e.target.value as string}))
    // }
    
    const hanleTitleChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
     
        setApplicationInformation((prevState) => ({...prevState, title: value.label}))
        setTitle({label: value.label, value: value.label})
    }
    const handleInputValueChange = (value: string, name: string) => {
      
        setApplicationInformation((prevState) => ({...prevState, [name]: value}))
    }
    const handleDOBChange = (value: any) => {
     
        if(value){
            setApplicationInformation((prevState) => ({...prevState, date_of_birth: value.format()}))
        }
        else {
            setApplicationInformation((prevState) => ({...prevState, date_of_birth: ''}))
        }
        
    }
    // const hanleCountryChange = (e: SelectChangeEvent) => {
    //     setApplicationInformation((prevState) => ({...prevState, country: e.target.value as string}))
    // }
    const hanleCountryChange = (e: React.SyntheticEvent, value: AutoCompleteSelectDataType) => {
    
        setApplicationInformation((prevState) => ({...prevState, country: value.label}))
        setCurrentSelectedCountry({label: value.label, value: value.label})
    }
    
    return (
        <div className={s.rightContainer}>
            <div className={s.formContainer}>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Title/Occupation <sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={occupations}
                            value={title}
                            onChange={hanleTitleChange}
                            errorText={invalidInputFields.includes("Title/Occupation")? 'Please select title/occupation.' : undefined}
                        />
                        {/* <DropdownInput
                            optionList={occupations}
                            value={applicationInformation.title}
                            onChange={hanleTitleChange}
                        /> */}
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>First Name <sup>*</sup></p>
                        <TextField
                            name="first_name"
                            value={applicationInformation.first_name}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("First Name")}
                            required={true}
                            error={invalidInputFields.includes("First Name") ? 'Please enter your first name.' : undefined}
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? cn(s.middleName, s.darkFont) : cn(s.middleName, s.lightFont)}>Middle Name(s) (if applicable)</p>
                        <TextField
                            name="middle_name"
                            value={applicationInformation.middle_name}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Last Name <sup>*</sup></p>
                        <TextField
                            name="last_name"
                            value={applicationInformation.last_name}
                            onChange={handleInputValueChange}
                            variant="xs"
                            externalError={invalidInputFields.includes("Last Name")}
                            error={invalidInputFields.includes("Last Name") ? 'Please enter your last name.' : undefined}
                        />
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Date of Birth<sup>*</sup></p>
                        <DatePicker onChange={handleDOBChange} value={applicationInformation.date_of_birth} error={invalidInputFields.includes("Date Of Birth")} errorText={invalidInputFields.includes("Date Of Birth")? 'Please enter valid date of birth.' : undefined}/>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.inputSectionHalf}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Country/Region<sup>*</sup></p>
                        <AutoCompleteSelect
                            optionList={countryList}
                            value={currentSelectedCountry}
                            onChange={hanleCountryChange}
                            errorText={invalidInputFields.includes("Country")? 'Please select country/region.' : undefined}
                        />
                        {/* <DropdownInput
                            optionList={countryList}
                            value={applicationInformation.country}
                            onChange={hanleCountryChange}
                        /> */}
                    </div>
                </div>
                <div className={s.row}>
                <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>LinkedIn</p>
                        <TextField
                            name="linkedin"
                            value={applicationInformation.linkedin}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Instagram</p>
                        <TextField
                            name="instagram"
                            value={applicationInformation.instagram}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                    <div className={s.inputSection}>
                        <p className={theme === 'dark' ? s.darkFont : s.lightFont}>Twitter</p>
                        <TextField
                            name="twitter"
                            value={applicationInformation.twitter}
                            onChange={handleInputValueChange}
                            variant="xs"
                            required={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationInformation;